/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";
/* eslint-enable no-unused-vars */

// mic start
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// mic end

class MTableActions extends React.Component {
  render() {
    if (this.props.draggableRows) {
      return <Icon>
        drag_handle
      </Icon>
    }
    if (this.props.actions) {
      return this.props.actions.map((action, index) => {
        if (action.component) {
          return <React.Fragment
            key={"action-" + index}
          >{action.component}</React.Fragment>
        } else {
          return (<this.props.components.Action
            action={action}
            key={"action-" + index}
            data={this.props.data}
            size={this.props.size}
            disabled={this.props.disabled}
          />)
        }
      })
    }
    return null;
  }
}

MTableActions.defaultProps = {
  actions: [],
  data: {},
};

MTableActions.propTypes = {
  components: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

export default MTableActions;
