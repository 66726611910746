/* eslint-disable import/prefer-default-export */

import Parse from 'widget/parse';
import {
  SET_COMPANY_START,
  SET_COMPANY_SUCCESS,
  SET_COMPANY_ERROR,
} from '../constants';

function getCompFromState(state) {
  // console.log('getCompFromState state', state)
  const comp = (state && state.comp) || {};
  const { initialNow, companyId = 'F2BByjzuro', companyObj = {} } = comp;

  return {
    initialNow,
    companyId,
    companyObj,
  };
}

export function getComp() {
  // console.log('getComp')
  return (dispatch, getState) => getCompFromState(getState());
}

export function setCompany({ companyId }) {
  // company 就是 companyId
  return async (dispatch, getState, { client, history }) => {
    dispatch({
      type: SET_COMPANY_START,
      payload: {
        companyId,
      },
    });

    try {
      // WARNING !!
      // do not use client.networkInterface except you want skip Apollo store
      // use client.query if you want benefit from Apollo caching mechanisms
      // const { data } = await client.query({
      //   query: queryComp,
      //   variables: { companyId },
      // });
      // const messages = data.comp.reduce((msgs, msg) => {
      //   msgs[msg.id] = msg.message; // eslint-disable-line no-param-reassign
      //   return msgs;
      // }, {});

      // server: 只有在第一次call的時候 才會被執行
      // browser: 每次更改語系，都會進到這邊來執行，其他時候在cookies端改而已
      // console.log('setCompany: companyId', companyId)
      const companyObj = (companyId && (await Parse.findDoc('company', { objectId: companyId }))) || {};
      // console.log(companyObj)

      // 去 reducer 設定目前的 redux 要放什麼資料
      dispatch({
        type: SET_COMPANY_SUCCESS,
        payload: {
          companyId,
          companyObj,
        },
      });

      // remember company for every new request
      if (process.env.BROWSER) {
        // 只有在前端
        const maxAge = 3650 * 24 * 3600; // 10 years in seconds
        document.cookie = `comp=${companyId};path=/;max-age=${maxAge}`;
        history.push(`?comp=${companyId}`);
      }

      // return bound comp instance at the end
      return getCompFromState(getState());
    } catch (error) {
      dispatch({
        type: SET_COMPANY_ERROR,
        payload: {
          companyId,
          error,
        },
      });
      return null;
    }
  };
}
