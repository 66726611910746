/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',
  // Keep in mind, routes are evaluated in order
  children: [{
    path: '/',
    load: () => import(/* webpackChunkName: 'official' */ './official'),
  },
  // {
  //   path: '/home',
  //   load: () => import(/* webpackChunkName: 'home' */ './home'),
  // },
  // {
  //   path: '/contact',
  //   load: () => import(/* webpackChunkName: 'contact' */ './contact'),
  // },
  {
    path: '/login',
    load: () => import(/* webpackChunkName: 'login' */ './auth/login'),
  }, {
    path: '/register',
    load: () => import(/* webpackChunkName: 'register' */ './auth/register'),
  }, {
    path: '/resetpassword',
    load: () => import(/* webpackChunkName: 'resetpassword' */ './auth/resetpassword'),
  }, {
    path: '/lock-screen',
    load: () => import(/* webpackChunkName: 'lock-screen' */ './auth/lock-screen'),
  },
  // {
  //   path: '/error404',
  //   load: () =>
  //     import(/* webpackChunkName: 'error404' */ './auth/error404'),
  // },
  // {
  //   path: '/privacy',
  //   load: () => import(/* webpackChunkName: 'privacy' */ './privacy'),
  // },
  {
    path: '/lesson',
    children: [{
      path: '/thinking',
      load: () => import(/* webpackChunkName: 'thinking' */ './cycuFront/index'),
    }, {
      path: '/ai',
      load: () => import(/* webpackChunkName: 'ai' */ './cycuFront/ai'),
    }, {
      path: '/programming',
      load: () => import(/* webpackChunkName: 'programming' */ './cycuFront/programming'),
    }]
  },
  {
    path: '/lecturer',
    children: [{
      path: '/intro',
      load: () => import(/* webpackChunkName: 'intro' */ './cycuFront/intro'),
    }, {
      path: '/interest',
      load: () => import(/* webpackChunkName: 'interest' */ './cycuFront/interest'),
    }, {
      path: '/schedule',
      load: () => import(/* webpackChunkName: 'schedule' */ './cycuFront/schedule'),
    }, {
      path: '/business',
      load: () => import(/* webpackChunkName: 'business' */ './cycuFront/business'),
    }]
  }, {
    path: '/game',
    children: [{
      path: '/doors/:tabId?',
      load: () => import(/* webpackChunkName: 'doors' */ './cycuFront/doors'),
    }, {
      path: '/betting/:tabId?/:tabId2?',
      load: () => import(/* webpackChunkName: 'betting' */ './cycuFront/betting'),
    }, {
      path: '/puzzle/:tabId?',
      load: () => import(/* webpackChunkName: 'puzzle' */ './cycuFront/puzzle'),
    }, {
      path: '/basket/:tabId?',
      load: () => import(/* webpackChunkName: 'basket' */ './cycuFront/basket'),
    }, {
      path: '/stack/:tabId?',
      load: () => import(/* webpackChunkName: 'stack' */ './cycuFront/stack'),
    }, {
      path: '/hanoi/:tabId?',
      load: () => import(/* webpackChunkName: 'hanoi' */ './cycuFront/hanoi'),
    }]
  }, {
    path: '/programming',
    children: [{
      path: '/coding',
      load: () => import(/* webpackChunkName: 'coding' */ './cycuFront/coding'),
    }, {
      path: '/fractal',
      load: () => import(/* webpackChunkName: 'fractal' */ './cycuFront/fractal'),
    }]
  }, {
    path: '/sharing',
    load: () => import(/* webpackChunkName: 'sharing' */ './cycuFront/sharing'),
  }, {
    path: '/resources',
    load: () => import(/* webpackChunkName: 'coding' */ './cycuFront/resources'),
  }, {
    path: '/webDesign',
    load: () => import(/* webpackChunkName: 'webDesign' */ './webDesign'),
  },
  // {
  //   path: '/page/:pageId?',
  //   load: () => import(/* webpackChunkName: 'webDesign' */ './webDesign'),
  // },
  {
    path: '/personalPolicy',
    load: () => import(/* webpackChunkName: 'personalPolicy' */ './personalPolicy'),
  },
  {
    path: '/privacyPolicy',
    load: () => import(/* webpackChunkName: 'privacyPolicy' */ './privacyPolicy'),
  },
  {
    path: '/siteMap',
    load: () => import(/* webpackChunkName: 'siteMap' */ './siteMap'),
  },
  { // ?
    path: '/shoppingCart',
    load: () => import(/* webpackChunkName: 'shoppingCart' */ './shoppingCart'),
  }, {
    path: '/iFace',
    load: () => import(/* webpackChunkName: 'iFace' */ './iface'),
  }, { // 實聯制
    path: '/user_register/:recordId?',
    load: () => import(/* webpackChunkName: 'userRegister' */ './iface/userRegister'),
  },
  {
    path: '/about',
    load: () => import(/* webpackChunkName: 'about' */ './shop/about'),
  },
  {
    path: '/donate/:p1Id?/:p2Id?',
    load: () => import(/* webpackChunkName: 'apply-donate' */ './shop/donate'),
  }, {
    path: '/payresult',
    load: () => import(/* webpackChunkName: 'payresult' */ './shop/donate-result'),
  }, {
    path: '/blog/:blogId?',
    load: () => import(/* webpackChunkName: 'blog' */ './shop/blog'),
  }, {
    path: '/gallery',
    load: () =>
      import(/* webpackChunkName: 'gallery' */ './shop/gallery'),
  }, {
    path: '/front',
    // action(context, data) {
    //   if (!context.auth || !context.auth.token) {
    //     return { redirect: '/login' } // stop and redirect
    //   }
    //   return context.next() // go to children
    // },
    children: [{
      path: '',
      load: () => import(/* webpackChunkName: 'index-page' */ './front/index-page'),
    }, {
      path: '/add-product',
      load: () => import(/* webpackChunkName: 'add-product' */ './front/add-product'),
    }, {
      path: '/nucleo-icons',
      load: () => import(/* webpackChunkName: 'nucleo-icons' */ './front/nucleo-icons'),
    }, {
      path: '/sections',
      load: () => import(/* webpackChunkName: 'sections' */ './front/sections'),
    }, {
      path: '/presentation',
      load: () => import(/* webpackChunkName: 'presentation' */ './front/presentation'),
    }, {
      path: '/about-us',
      load: () => import(/* webpackChunkName: 'about-us' */ './front/about-us'),
    }, {
      path: '/blog-post',
      load: () => import(/* webpackChunkName: 'blog-post' */ './front/blog-post'),
    }, {
      path: '/blog-posts',
      load: () => import(/* webpackChunkName: 'blog-posts' */ './front/blog-posts'),
    }, {
      path: '/contact-us',
      load: () => import(/* webpackChunkName: 'contact-us' */ './front/contact-us'),
    }, {
      path: '/discover',
      load: () => import(/* webpackChunkName: 'discover' */ './front/discover'),
    }, {
      path: '/e-commerce',
      load: () => import(/* webpackChunkName: 'e-commerce' */ './front/e-commerce'),
    }, {
      path: '/error-404',
      load: () => import(/* webpackChunkName: 'error-404' */ './front/error-404'),
    }, {
      path: '/error-422',
      load: () => import(/* webpackChunkName: 'error-422' */ './front/error-422'),
    }, {
      path: '/error-500',
      load: () => import(/* webpackChunkName: 'error-500' */ './front/error-500'),
    }, {
      path: '/landing-page',
      load: () => import(/* webpackChunkName: 'landing-page' */ './front/landing-page'),
    }, {
      path: '/login-page',
      load: () => import(/* webpackChunkName: 'login-page' */ './front/login-page'),
    }, {
      path: '/product-page',
      load: () => import(/* webpackChunkName: 'product-page' */ './front/product-page'),
    }, {
      path: '/profile-page',
      load: () => import(/* webpackChunkName: 'profile-page' */ './front/profile-page'),
    }, {
      path: '/register-page',
      load: () => import(/* webpackChunkName: 'register-page' */ './front/register-page'),
    }, {
      path: '/search-with-sidebar',
      load: () => import(/* webpackChunkName: 'search-with-sidebar' */ './front/search-with-sidebar'),
    }, {
      path: '/settings',
      load: () => import(/* webpackChunkName: 'settings' */ './front/settings'),
    }, {
      path: '/twitter-redesign',
      load: () => import(/* webpackChunkName: 'twitter-redesign' */ './front/twitter-redesign'),
    }, {
      path: '/startUpEdit',
      load: () => import(/* webpackChunkName: 'startUpEdit' */ './front/startUpEdit'),
    }, {
      path: '/startUpPreview',
      load: () => import(/* webpackChunkName: 'startUpPreview' */ './front/startUpPreview'),
    },
    ],
  }, {
    path: '/admin',
    action(context, data) {
      if (!context.auth || !context.auth.token) {
        return { redirect: '/login' }; // stop and redirect
      }
      return context.next(); // go to children
    },
    children: [{
      path: '',
      load: () => import(/* webpackChunkName: 'dashboard' */ './dashboard'),
    }, {
      path: '/dashboard',
      load: () => import(/* webpackChunkName: 'dashboard' */ './dashboard'),
    }, {
      path: '/user-profile',
      load: () => import(/* webpackChunkName: 'user-profile' */ './admin/userProfile'),
    }, {
      path: '/user-changepw',
      load: () => import(/* webpackChunkName: 'user-changepw' */ './admin/userChangePW'),
    }, {
      path: '/temple',
      children: [{
        //   path: '/clients',
        //   load: () => import(/* webpackChunkName: 'clients' */ './clients'),
        // }, {
        path: '/family/:familyId?/:t1?/:t1Id?',
        load: () => import(/* webpackChunkName: 'family' */ './temple/family'),
      }, {
        path: '/buddhist',
        load: () => import(/* webpackChunkName: 'buddhist' */ './temple/Buddhist'),
      }, {
        path: '/templelist/:tId?',
        load: () => import(/* webpackChunkName: 'templelist' */ './temple/templelist'),
      }, {
        path: '/voucher/:tabId?',
        load: () => import(/* webpackChunkName: 'voucher' */ './temple/voucher'),
      }, {
        path: '/borrowgod/:tabId?',
        load: () => import(/* webpackChunkName: 'borrowgod' */ './temple/BorrowGod'),
      }, {
        path: '/print/:t1?/:t1Id?',
        load: () => import(/* webpackChunkName: 'print' */ './temple/Print'),
      }, {
        path: '/cardslot',
        load: () => import(/* webpackChunkName: 'cardslot' */ './temple/cardslot'),
      }, {
        path: '/cashier/:tabId?',
        load: () => import(/* webpackChunkName: 'cashier' */ './temple/Cashier'),
      }, {
        path: '/statistic',
        load: () => import(/* webpackChunkName: 'statistic' */ './temple/statistic'),
      }, {
        path: '/pray/:pray1Id?',
        load: () => import(/* webpackChunkName: 'pray' */ './temple/pray'),
      }, {
        path: '/light/:light1Id?',
        load: () => import(/* webpackChunkName: 'light' */ './temple/light'),
      }, {
        path: '/positionlight/:light1Id?',
        load: () => import(/* webpackChunkName: 'lightPosition' */ './temple/lightPosition'),
      }, {
        path: '/posprintlight',
        load: () => import(/* webpackChunkName: 'posprintlight' */ './temple/lightPositionPrint'),
      }, {
        path: '/settings/:tabId?',
        load: () => import(/* webpackChunkName: 'templesettings' */ './temple/settings'),
      }, {
        path: '/donate/:tabId?',
        load: () => import(/* webpackChunkName: 'donate' */ './temple/donate'),
      }, {
        path: '/import',
        load: () => import(/* webpackChunkName: 'templeimport' */ './temple/import'),
      },
      // {
      //   path: '/email',
      //   load: () => import(/* webpackChunkName: 'Email' */ './temple/email'),
      // },
      {
        path: '/sms/:tabId?',
        load: () => import(/* webpackChunkName: 'sms' */ './temple/sms'),
      }
      ]
    }, {
      path: '/shop',
      children: [{
        path: '/officialdirectory',
        load: () => import(/* webpackChunkName: 'OfficialDirectory' */ './bobee/officialdirectory'),
      }, {
        path: '/thirdpartymarketing/:p1?',
        load: () => import(/* webpackChunkName: 'ThirdPartyMarketing' */ './bobee/thirdpartymarketing'),
      }, {
        path: '/officialform',
        load: () => import(/* webpackChunkName: 'OfficialForm' */ './bobee/officialform'),
      }, {
        path: '/officialonepage',
        load: () => import(/* webpackChunkName: 'OfficialOnePage' */ './bobee/officialonepage'),
      },
      ]
    }, {
      path: '/cycu',
      children: [{
        path: '/lesson/:p1?',
        load: () => import(/* webpackChunkName: 'lesson' */ './cycu/lesson'),
      }, {
        path: '/onclass/:p1?',
        load: () => import(/* webpackChunkName: 'lineclass' */ './cycu/lineclass')
      },
      ]
    }, {
      path: '/bobee',
      children: [{
        path: '/productpage/:p1?/:p2?',
        load: () => import(/* webpackChunkName: 'ProductPage' */ './bobee/productpage'),
      }, {
        path: '/appsetting/photosedit',
        load: () => import(/* webpackChunkName: 'AppSettingPhotosEdit' */ './bobee/appsetting/photosedit'),
      }, {
        path: '/appsetting/:tabId?',
        load: () => import(/* webpackChunkName: 'AppSetting' */ './bobee/appsetting'),
      }, {
        path: '/sms/:tabId?',
        load: () => import(/* webpackChunkName: 'sms' */ './bobee/sms'),
      }, {
        path: '/orderlist/:praying1Id?',
        load: () => import(/* webpackChunkName: 'orderlist' */ './bobee/orderlist'),
      }, {
        path: '/clientpage',
        load: () => import(/* webpackChunkName: 'ClientPage' */ './bobee/clientpage'),
      }, {
        path: '/orderpage/:p1?',
        load: () => import(/* webpackChunkName: 'OrderPage' */ './bobee/orderpage'),
      }, {
        path: '/ordersetting/:p1?',
        load: () => import(/* webpackChunkName: 'OrderSetting' */ './bobee/ordersetting'),
      }, {
        path: '/promotion',
        load: () => import(/* webpackChunkName: 'Promotion' */ './bobee/promotion'),
      }, {
        path: '/freeshipping',
        load: () => import(/* webpackChunkName: 'FreeShipping' */ './bobee/freeshipping'),
      }, {
        path: '/officialblog',
        load: () => import(/* webpackChunkName: 'OfficialBlog' */ './bobee/officialblog'),
      }, {
        path: '/officialdesign/:treeId?',
        load: () => import(/* webpackChunkName: 'OfficialDesign' */ './bobee/officialdesign'),
      }, {
        path: '/analysiscenter/:p1?/:p2?/:p3?',
        load: () => import(/* webpackChunkName: 'AnalysisCenter' */ './bobee/analysiscenter'),
      }, {
        path: '/officialedit',
        load: () => import(/* webpackChunkName: 'OfficialEdit' */ './bobee/officialedit'),
      },
      ]
    }, {
      path: '/school',
      children: [{
        path: '/studentpage/:list/:_id?/:activeIndex1?/:activeIndex2?',
        load: () => import(/* webpackChunkName: 'StudentPage' */ './school/studentPage'),
      }, {
        path: '/section/:list0/:list1?/:list2?',
        load: () => import(/* webpackChunkName: 'SectionPage' */ './school/sectionPage'),
      }, {
        path: '/agentlist',
        load: () => import(/* webpackChunkName: 'AgentList' */ './school/recruitment/AgentList'),
      }, {
        path: '/studentprint/:list',
        load: () => import(/* webpackChunkName: 'StudentPrint' */ './school/studentPrint'),
      }, {
        path: '/dormpage/:_id?/:roomid?/:activeIndex?',
        load: () => import(/* webpackChunkName: 'DormPage' */ './school/generalAffair/dormPage'),
      }, {
        path: '/teacherpage/:_id?',
        load: () => import(/* webpackChunkName: 'TeacherPage' */ './school/Teacher'),
      }
      ]
    }, {
      path: '/dormClient/:clientId?/:t1?/:t1Id?',
      load: () => import(/* webpackChunkName: 'dormClient' */ './dorm/dormClient'),
    }, {
      path: '/ManageRoom',
      load: () => import(/* webpackChunkName: 'ManageRoom' */ './dorm/ManageRoom'),
    }, {
      path: '/Expenditure',
      load: () => import(/* webpackChunkName: 'Expenditure' */ './dorm/Expenditure'),
    }, {
      path: '/MeterList',
      load: () => import(/* webpackChunkName: 'MeterList' */ './dorm/MeterList'),
    }, {
      path: '/ClientCalendar',
      load: () => import(/* webpackChunkName: 'ClientCalendar' */ './dorm/ClientCalendar'),
    }, {
      path: '/ClientContact',
      load: () => import(/* webpackChunkName: 'ClientContact' */ './dorm/ClientContact'),
    }, {
      path: '/EveryMonth',
      load: () => import(/* webpackChunkName: 'EveryMonth' */ './dorm/EveryMonth'),
    }, {
      path: '/CheckLineLink',
      load: () => import(/* webpackChunkName: 'CheckLineLink' */ './dorm/CheckLineLink'),
    }, {
      path: '/buttons',
      load: () => import(/* webpackChunkName: 'buttons' */ './admin/buttons'),
    }, {
      path: '/gridsystem',
      load: () => import(/* webpackChunkName: 'gridsystem' */ './admin/gridsystem'),
    }, {
      path: '/users',
      load: () => import(/* webpackChunkName: 'users' */ './admin/users'),
    }, {
      path: '/allusers',
      load: () => import(/* webpackChunkName: 'allusers' */ './admin/allusers'),
    }, {
      path: '/role',
      load: () => import(/* webpackChunkName: 'role' */ './admin/role'),
    }, {
      path: '/escpos',
      load: () => import(/* webpackChunkName: 'escpos' */ './admin/escpos'),
    }, {
      path: '/system/:tabId?',
      load: () => import(/* webpackChunkName: 'system' */ './admin/system'),
    }, {
      path: '/fileCenter',
      load: () => import(/* webpackChunkName: 'fileCenter' */ './files/fileCenter'),
    }, {
      path: '/albumCenter',
      load: () => import(/* webpackChunkName: 'albumCenter' */ './files/albumCenter'),
    }, {
      path: '/announceCenter',
      load: () => import(/* webpackChunkName: 'announceCenter' */ './files/announceCenter'),
    }, {
      path: '/companies',
      load: () => import(/* webpackChunkName: 'companies' */ './admin/companies'),
    }, {
      path: '/shop',
      load: () => import(/* webpackChunkName: 'shop' */ './admin/shop'),
    }, {
      path: '/alliface',
      load: () => import(/* webpackChunkName: 'alliface' */ './admin/alliface'),
    }, {
      path: '/ziweiCal',
      load: () => import(/* webpackChunkName: 'ziweiCal' */ './ziwei/ziweiCal'),
    }, {
      path: '/ziweiReact',
      load: () => import(/* webpackChunkName: 'ziweiReact' */ './ziwei/ziweiReact'),
    }, {
      path: '/roles',
      load: () => import(/* webpackChunkName: 'roles' */ './admin/roles'),
    }, {
      path: '/panels',
      load: () => import(/* webpackChunkName: 'panels' */ './admin/panels'),
    }, {
      path: '/sweetalert',
      load: () => import(/* webpackChunkName: 'sweetalert' */ './admin/sweetalert'),
    }, {
      path: '/notifications',
      load: () => import(/* webpackChunkName: 'notifications' */ './admin/notifications'),
    }, {
      path: '/icons',
      load: () => import(/* webpackChunkName: 'icons' */ './admin/icons'),
    }, {
      path: '/typography',
      load: () => import(/* webpackChunkName: 'typography' */ './admin/typography'),
    }, {
      path: '/timeline',
      load: () => import(/* webpackChunkName: 'timeline' */ './admin/timeline'),
    }, {
      path: '/userprofile',
      load: () => import(/* webpackChunkName: 'userProfile' */ './admin/userProfile'),
    }, {
      path: '/regularforms',
      load: () => import(/* webpackChunkName: 'regularforms' */ './admin/regularforms'),
    }, {
      path: '/extendedforms',
      load: () => import(/* webpackChunkName: 'extendedforms' */ './admin/extendedforms'),
    }, {
      path: '/validationforms',
      load: () => import(/* webpackChunkName: 'validationforms' */ './admin/validationforms'),
    }, {
      path: '/visitorsrecord',
      load: () => import(/* webpackChunkName: 'visitorsrecord' */ './admin/visitorsrecord'),
    }, {
      path: '/immediatemap',
      load: () => import(/* webpackChunkName: 'immediatemap' */ './admin/immediatemap'),
    }, {
      path: '/wizard',
      load: () => import(/* webpackChunkName: 'wizard' */ './admin/wizard'),
    }, {
      path: '/regulartables',
      load: () => import(/* webpackChunkName: 'regulartables' */ './admin/regulartables'),
    }, {
      path: '/extendedtables',
      load: () => import(/* webpackChunkName: 'extendedtables' */ './admin/extendedtables'),
    }, {
      path: '/reacttables',
      load: () => import(/* webpackChunkName: 'reacttables' */ './admin/reacttables'),
    }, {
      path: '/googlemaps',
      load: () => import(/* webpackChunkName: 'googlemaps' */ './admin/googlemaps'),
    }, {
      path: '/fullscreenmap',
      load: () => import(/* webpackChunkName: 'fullscreenmap' */ './admin/fullscreenmap'),
    }, {
      path: '/vectormap',
      load: () => import(/* webpackChunkName: 'vectormap' */ './admin/vectormap'),
    }, {
      path: '/widgets',
      load: () => import(/* webpackChunkName: 'widgets' */ './admin/widgets'),
    }, {
      path: '/charts',
      load: () => import(/* webpackChunkName: 'charts' */ './admin/charts'),
    }, {
      path: '/calendar',
      load: () => import(/* webpackChunkName: 'calendar' */ './admin/calendar'),
    }, {
      path: '/Cash',
      load: () => import(/* webpackChunkName: 'Cash' */ './admin/accounting/Cash'),
    }, {
      path: '/IncomeStatement',
      load: () => import(/* webpackChunkName: 'IncomeStatement' */ './admin/accounting/IncomeStatement'),
    }, {
      path: '/SalaryList',
      load: () => import(/* webpackChunkName: 'SalaryList' */ './admin/accounting/SalaryList'),
    }, {
      path: '/Statistic1',
      load: () => import(/* webpackChunkName: 'Statistic1' */ './admin/accounting/Statistic1'),
    }, {
      path: '/SubjectSetting',
      load: () => import(/* webpackChunkName: 'SubjectSetting' */ './admin/accounting/SubjectSetting'),
    }, {
      path: '/ClockIn',
      load: () => import(/* webpackChunkName: 'ClockIn' */ './admin/clock/ClockIn'),
    }, {
      path: '/ClockManage',
      load: () => import(/* webpackChunkName: 'ClockManage' */ './admin/clock/ClockManage'),
    }, {
      path: '/ClockSalary',
      load: () => import(/* webpackChunkName: 'ClockSalary' */ './admin/clock/ClockSalary'),
    }, {
      path: '/LineChat',
      load: () => import(/* webpackChunkName: 'LineChat' */ './admin/line/LineChat'),
    }, {
      path: '/LinePay',
      load: () => import(/* webpackChunkName: 'LinePay' */ './admin/line/LinePay'),
    }, {
      path: '/LineMessage',
      load: () => import(/* webpackChunkName: 'LineMessage' */ './admin/line/LineMessage'),
    }, {
      path: '/LineNews',
      load: () => import(/* webpackChunkName: 'LineNews' */ './admin/line/LineNews'),
    }, {
      path: '/LineSetting',
      load: () => import(/* webpackChunkName: 'LineSetting' */ './admin/line/LineSetting'),
    }, {
      path: '/linerichmenu',
      load: () => import(/* webpackChunkName: 'linerichmenu' */ './admin/line/LineRichMenu'),
    }, {
      path: '/emailReceive',
      load: () => import(/* webpackChunkName: 'emailReceive' */ './emailReceive'),
    }, {
      path: '/CrewOpenTable/:ordering1Id?',
      load: () => import(/* webpackChunkName: 'CrewOpenTable' */ './restaurant/CrewOpenTable'),
    }, {
      path: '/Reserve',
      load: () => import(/* webpackChunkName: 'Reserve' */ './restaurant/Reserve'),
    }, {
      path: '/CrewBorrow',
      load: () => import(/* webpackChunkName: 'CrewBorrow' */ './restaurant/CrewBorrow'),
    }, {
      path: '/CrewWarehouse',
      load: () => import(/* webpackChunkName: 'CrewWarehouse' */ './restaurant/CrewWarehouse'),
    }, {
      path: '/CrewStorage',
      load: () => import(/* webpackChunkName: 'CrewStorage' */ './restaurant/CrewStorage'),
    }, {
      path: '/Members',
      load: () => import(/* webpackChunkName: 'Members' */ './restaurant/Members'),
    }, {
      path: '/CrewTablestatus',
      load: () => import(/* webpackChunkName: 'CrewTablestatus' */ './restaurant/CrewTablestatus'),
    }, {
      path: '/CrewService',
      load: () => import(/* webpackChunkName: 'CrewService' */ './restaurant/CrewService'),
    }, {
      path: '/CrewAllorder',
      load: () => import(/* webpackChunkName: 'CrewAllorder' */ './restaurant/CrewAllorder'),
    }, {
      path: '/Managegames',
      load: () => import(/* webpackChunkName: 'Managegames' */ './restaurant/Managegames'),
    }, {
      path: '/Managefood',
      load: () => import(/* webpackChunkName: 'Managefood' */ './restaurant/Managefood'),
    }, {
      path: '/Managetables',
      load: () => import(/* webpackChunkName: 'Managetables' */ './restaurant/Managetables'),
    }, {
      path: '/iface',
      children: [{
        path: '/dashboard',
        load: () => import(/* webpackChunkName: 'ifaceDashboard' */ './iface/dashboard'),
      }, {
        path: '/list',
        load: () => import(/* webpackChunkName: 'ifaceList' */ './iface/list'),
      }, {
        path: '/user/:listType?/:userId?',
        load: () => import(/* webpackChunkName: 'ifaceUser' */ './iface/User'),
      }, {
        path: '/FamiliarManagement',
        load: () => import(/* webpackChunkName: 'ifaceFamiliarManagement' */ './iface/FamiliarManagement'),
      }, {
        path: '/linechat',
        load: () => import(/* webpackChunkName: 'ifaceLinechat' */ './iface/linechat'),
      }, {
        path: '/control',
        load: () => import(/* webpackChunkName: 'ifaceControl' */ './iface/control'),
      }, {
        path: '/immediaterecord',
        load: () => import(/* webpackChunkName: 'ifaceImmediaterecord' */ './iface/immediaterecord'),
      }, {
        path: '/dataoverview',
        load: () => import(/* webpackChunkName: 'ifaceDataoverview' */ './iface/dataoverview'),
      }, {
        path: '/clockrecord',
        load: () => import(/* webpackChunkName: 'ifaceClockrecord' */ './iface/clockrecord'),
      }, {
        path: '/history',
        load: () => import(/* webpackChunkName: 'ifaceHistory' */ './iface/history'),
      }, {
        path: '/capture',
        load: () => import(/* webpackChunkName: 'ifaceCapture' */ './iface/capture'),
      }, {
        path: '/group',
        load: () => import(/* webpackChunkName: 'ifaceGroup' */ './iface/group'),
      }
      ]
    }, {
      path: '/Hr1',  ///原hanbury 4-2
      load: () => import(/* webpackChunkName: 'Hr1' */ './iface/HrApplication'),
    }, {
      path: '/Hr2',
      load: () => import(/* webpackChunkName: 'Hr2' */ './iface/HrOverview'),
    }, {
      path: '/Hr3',
      load: () => import(/* webpackChunkName: 'Hr3' */ './iface/HrSetting'),
    },
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'adminError404' */ './admin/error404'),
    },
    ],
  },
  // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
  // {
  //   path: '/:pageId',
  //   load: () => import(/* webpackChunkName: 'official' */ './official'),
  // },
  {
    path: '(.*)',
    // load: () => import(/* webpackChunkName: 'notFound' */ './not-found'),
    load: () => import(/* webpackChunkName: 'error404' */ './auth/error404'),
    // load: () => import(/* webpackChunkName: 'official' */ './official'),
  },
  ],

  async action({ next, ...props }) {
    // Execute each child route until one of them return the result
    const route = await next();
    // Provide default values for title, description etc.
    const company = props.companyObj || {};
    route.title = `${route.title || '(未命名頁面)'} | ${company.name || ''}`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
