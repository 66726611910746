import React, { Component, Fragment } from 'react';


// 這個還蠻重要的 Universal Router API
// https://github.com/kriasoft/universal-router/blob/master/docs/api.md
// http://slides.com/koistya/universal-router
import UniversalRouter from 'universal-router';
import routes from './routes';

import MyEditTable from 'widget/material-table/MyEditTable';

export const arrUse = (props = {}) => ([{
  name: 'useTemple', icon: 'cloud', title: 'ERP 寺廟管理系統', subtitle: '針對佛教、道教設計，信眾管理、法會建檔、PDF列印',
  component: null,
  price: '$3,000/月',
},
{
  name: 'useShop', icon: 'computer', title: 'Shop 線上商城', subtitle: '快速打造商家官網、購物商城、線上收款出貨',
  price: '$3,000/月',
  component: null
},
{
  name: 'useLine', icon: 'podcast', title: 'LINE 機器人', subtitle: '針對客戶分群分眾，手機推播、簡訊發送、電子DM',
  price: '$1,000/月',
  component: null,
  settings: [
    { title: 'channelId', field: 'channelId' },
    { title: 'channelAccessToken', field: 'channelAccessToken' },
    { title: 'channelSecret', field: 'channelSecret' },
  ]
},
{
  name: 'useSchool', icon: 'cloud', title: 'ERP 學校管理系統', subtitle: '出席點名、成績統計、學生資料',
  price: '$3,000/月',
  component: null
},
{
  name: 'useDorm', icon: 'cloud', title: 'ERP 套房管理系統', subtitle: '收租提醒、訊息管理、水電報修',
  price: '$3,000/月',
  component: null
},
{
  name: 'useOfficial', icon: 'computer', title: 'Web 官方網站', subtitle: '快速打造商家官網、購物商城、線上收款出貨',
  price: '$3,000/月',
  component: null
},
{
  name: 'useIFace', icon: 'mobile alternate', title: 'iFace 人臉感溫門禁機', subtitle: '人員報到、結果通知、體溫量測',
  price: '免費/月',
  component: <MyEditTable {...props} dbName='IFaceList' />
},
// {
//   name: 'useAccount', icon: 'mobile alternate', title: 'Account 會計系統', subtitle: '',
//   price: '免費/月',
//   component: null
// },
// {
//   name: 'useMLM', icon: 'mobile', title: 'E-Commerce 精準行銷', subtitle: '',
//   price: '免費/月',
//   component: null
// },
// {
//   name: 'useAttendance', icon: 'mobile alternate', title: '出席', subtitle: '',
//   price: '免費/月',
//   component: null
// },
{
  name: 'useRestaurant', icon: 'mobile alternate', title: 'ERP 餐廳管理系統', subtitle: '',
  price: '免費/月',
  component: null
},
{
  name: 'useMakeup', icon: 'mobile alternate', title: '美妝', subtitle: '',
  price: '免費/月',
  component: null
},
{
  name: 'useHealthy', icon: 'mobile', title: '看護', subtitle: '',
  price: '免費/月',
  component: null
},
{
  name: 'useStreaming', icon: 'mobile', title: '串流', subtitle: '',
  price: '免費/月',
  component: null
},
{
  name: 'useZiwei', icon: 'mobile', title: '紫微', subtitle: '',
  price: '免費/月',
  component: null
},
{
  name: 'useTest', icon: 'mobile', title: '考試', subtitle: '',
  price: '免費/月',
  component: null
},
{
  name: 'usePawnshop', icon: 'mobile', title: '當舖', subtitle: '',
  price: '免費/月',
  component: null
},
{
  name: 'useCar', icon: 'mobile', title: '汽車', subtitle: '',
  price: '免費/月',
  component: null
},
{
  name: 'useInvest', icon: 'mobile', title: '投資', subtitle: '',
  price: '免費/月',
  component: null
},
{
  name: 'useDevelop', icon: 'mobile', title: '開發用', subtitle: '',
  price: '免費/月',
  component: null
},
])

export const arrUseItem = arrUse().map(({ name }) => name);

export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    if (typeof context.route.load === 'function') {
      return context.route
        .load()
        .then(action => action.default(context, params));
    }
    if (typeof context.route.action === 'function') {
      return context.route.action(context, params);
    }
    return undefined;
  },
});
