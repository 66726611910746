// import React, { Component, } from 'react';
import { toast } from 'react-semantic-toasts';

/**
title - The header of the toast
description - The content of the toast
type - Can be one of info, success, warning, or error
icon - Override the default icon
color - Override color with semantic values
size - Size of toast with semantic values
list - Array of strings for showing an item menu inside the toast
time - Duration to keep the toast open, 0 to wait until closed by the user
onClose - The function that will be called when the toast is closed (either if you have clicked the close sign or if the toast has been closed after time has passed)
onClick - The function that will be called when you click on the toast
onDismiss - The function that will be called when you click to close the toast. onClose function will be called afterwards.
animation - Override the default toast container animation
 */
const myToast = (props = {}) => {
  // console.log('123')
  const obj = {
    type: 'success', title: '儲存成功', description: '', icon: 'save', animation: 'bounce', time: 3000,
    ...props
  }
  toast(obj);
}

export default myToast;
