import { fetch as nfetch } from 'widget/simple-fetch-cache'
const formurlencoded = require('form-urlencoded');

const searchParams = params =>
  Object.keys(params)
    .map(key => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join('&');


export const fetchData = (url, data = {}, options = {}) => {
  // Default options are marked with *
  const { method = 'GET', cache = 'no-cache', mode = 'cors' } = options;
  const header0 = {
    cache, // : 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      // 'user-agent': 'Mozilla/4.0 MDN Example',
      'content-type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
    },
    // method: 'POST', // *GET, POST, PUT, DELETE, etc.
    method,
    mode, // no-cors, cors, *same-origin
    // redirect: 'follow', // manual, *follow, error
    // referrer: 'no-referrer', // *client, no-referrer
  };

  let header = header0;
  const query = formurlencoded(data)
  if (method === 'GET') {
    return nfetch(`${url}?${query}`, header); // .then(response => response.json()) // 輸出成 json
  }
  header = {
    body: query, // must match 'Content-Type' header
    ...header0,
  };
  return nfetch(url, header).then(response => response.json()); // 輸出成 json
};

export const fetchGz2Txt = (url, data = {}, options = {}) => {
  // Default options are marked with *
  const { method = 'GET', cache = 'force-cache' } = options;
  const header0 = {
    cache, // : 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      // 'user-agent': 'Mozilla/4.0 MDN Example',
      'content-type': 'application/x-www-form-urlencoded',
      'Accept-encoding': 'gzip,deflate',
    },
    // method: 'POST', // *GET, POST, PUT, DELETE, etc.
    method,
    // mode: 'cors', // no-cors, cors, *same-origin
    // redirect: 'follow', // manual, *follow, error
    // referrer: 'no-referrer', // *client, no-referrer
  };

  let header = header0;
  if (method === 'GET') {
    const esc = encodeURIComponent;
    const query = Object.keys(data)
      .map(k => `${esc(k)}=${esc(data[k])}`)
      .join('&');
    // console.log(`${url}?${query}`)
    // return nfetch(`${url}${query ? `?${query}` : ''}`, header).then(
    return nfetch(`${url}${query ? `?${query}` : ''}`, 86400 * 365)
      .then(response => {
        try {
          const res = response.clone().text();
          // console.log('http2 nfetch', res.length, response)
          return res
          // const res = await (response && response.arrayBuffer());
          // const data = new Uint8Array(res);
          // const decompressed = pako.inflate(data, { to: 'string' })
          // return decompressed
        } catch (err) {
          console.log(err.message)
          return res
        }
      }
      )
    // .then(res2 => {
    //   return res2
    // })
  }
  header = {
    body: searchParams(data), // must match 'Content-Type' header
    ...header0,
  };
  return nfetch(url, header).then(response => response.json()); // 輸出成 json
};

export const fetchJson = (url, data = {}, options = {}) => {
  // Default options are marked with *
  const { method = 'GET', cache = 'no-cache' } = options;
  const header0 = {
    cache, // : 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      'user-agent': 'Mozilla/4.0 MDN Example',
      'content-type': 'application/json',
    },
    // method: 'POST', // *GET, POST, PUT, DELETE, etc.
    method,
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer
  };

  let header = header0;
  if (method === 'GET') {
    // const esc = encodeURIComponent;
    // const query = Object.keys(data)
    //   .map(k => `${esc(k)}=${esc(data[k])}`)
    //   .join('&');
    // console.log(`${url}?${query}`)
    const query = formurlencoded(data)
    return nfetch(`${url}?${query}`, header).then(response => response.json()); // 輸出成 json
  }
  header = {
    body: JSON.stringify(data), // must match 'Content-Type' header
    ...header0,
  };
  return nfetch(url, header).then(response => response.json()); // 輸出成 json
};

export const frontFetchData = (url, data = {}, options = {}) => {
  console.log('frontFetchData', url, options);
  // Default options are marked with *
  const { method = 'GET', cache = 'no-cache', mode = 'cors' } = options;
  const header0 = {
    cache, // : 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      // 'user-agent': 'Mozilla/4.0 MDN Example',
      'content-type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
    },
    // method: 'POST', // *GET, POST, PUT, DELETE, etc.
    method,
    mode, // no-cors, cors, *same-origin
    // redirect: 'follow', // manual, *follow, error
    // referrer: 'no-referrer', // *client, no-referrer
  };
  let header = header0;
  const query = formurlencoded(data)
  if (method === 'GET') {
    return fetch(`${url}?${query}`, header).then(response => response.json()).catch(err => console.log(err)) // 輸出成 json
  }
  header = {
    body: query, // must match 'Content-Type' header
    ...header0,
  };

  return fetch(url, header).then(response => response.json()); // 輸出成 json
};

export default fetchJson;
