import Parse from 'parse';
import Resizer from 'react-image-file-resizer';
import { frontFetchData } from 'widget/http2';
import { str2pinyin } from '../multilang';
import myToast from 'widget/MyToast';

const { serverURL, iwCloudURL, iwCloudTestURL, liveQueryServerURL, appID, clientKey } = require('./conf.js');

Parse.serverURL = serverURL;
// Parse.iwCloudURL = iwCloudTestURL;
Parse.iwCloudURL = iwCloudURL;
Parse.liveQueryServerURL = liveQueryServerURL;
Parse.initialize(appID, clientKey);

// Parse.LiveQuery.on('open', () => {
//   console.log('socket connection established');
// });

// Parse.LiveQuery.on('close', () => {
//   console.log('socket connection closed');
// });

// Parse.LiveQuery.on('error', (error) => {
//   console.log(error);
// });

// Parse.generateObjectId = () => {
//   return Math.random().toString(36).slice(-8);
// }

Parse.sendmail = async params => {
  await Parse.Cloud.run('sendmail', params);
  return '1';
};

Parse.changeUserPassword = async (username, oldPassword, newPassword) => {
  const res = await Parse.Cloud.run('changeUserPassword', { username, oldPassword, newPassword, }).catch(error => {
    console.error(`${error.code} ${error.message}`);
  });
  return res;
};

Parse.resetpw = async email => {
  // Parse.sendmail
  // Math.random().toString(36).slice(-8);
  return new Promise((resolve, reject) => {
    Parse.User.requestPasswordReset(email)
      .then(res => {
        resolve('1');
      })
      .catch(error => {
        console.error(`${error.code} ${error.message}`);
        resolve(`Error: ${error.code} ${error.message}`);
      });
  });
};

const imageFileResizer = async (file, maxX, maxY) => {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      maxX,
      maxY,
      'JPEG',
      75,
      0,
      async uri => {
        // console.log(uri)
        resolve(uri);
      },
      'base64',
    );
  });
};

Parse.saveFile = async (file, id, docId, dbName, fileFieldName) => {
  const parseFile = new Parse.File(id + str2pinyin(file.name), file, file.type);
  const theFile = await parseFile.save().catch(err => console.error(err));
  // console.log(file, id, str2pinyin(file.name), docId, dbName, fileFieldName)
  // console.log(theFile)
  const classDb = Parse.Object.extend(dbName);
  const newDoc = new classDb();
  newDoc.id = docId;
  newDoc.set(fileFieldName, theFile);
  const doc = await newDoc.save();
  return { doc, theFile, };
};

Parse.putFile = async (file, id = '') => {
  const parseFile = new Parse.File(id + str2pinyin(file.name), file, file.type);
  const theFile = await parseFile.save().catch(err => console.error(err));
  return theFile;
};

const getDimensions = (image) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.src = image;
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
  })
}

const saveImg = async (file, id = '', maxX = 2048, maxY = 1152) => {
  const base64 = await imageFileResizer(file, maxX, maxY);
  const dim = await getDimensions(base64)
  const parseFile = new Parse.File(id + str2pinyin(file.name), { base64 }, 'image/jpg',);
  const theFile = await parseFile.save().catch(err => console.error(err));
  return { theFile, dim };
};

// Parse.iwCloud = async (funcName, data = {}, options = {}) => {
//   // const resJson = await frontFetchData('/cloud/register', data, { method: 'POST' })
//   const { method = 'POST', mode = 'no-cors' } = options
//   const resJson = await frontFetchData(`${Parse.iwCloudURL}/${funcName}`, data, { method, mode })
//     .then(result => result)
//     .catch(err => console.log(err))
//   return resJson;
// }

Parse.iwCloud = async (funcName, data = {}, options = {}) => {
  const response = await fetch(`${Parse.iwCloudURL}/${funcName}`, {
    method: "POST",
    headers: {
      "content-type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(data)
  })
  const res = await response.json();
  return res
}

Parse.saveImgThumb = async (file, dbName, docId, imgName, thumbName) => {
  // console.log(dbName, docId, imgName, thumbName)
  // console.log(file);
  const { theFile: theFile0, dim: dim0 } = await saveImg(file, '0', 10000, 10000);
  const { theFile: theFile1, dim: dim1 } = await saveImg(file, '1', 2048, 1152);
  const { theFile: theFile2, dim: dim2 } = await saveImg(file, '2', 640, 360);
  // console.log(theFile1, theFile2)
  const classDb = Parse.Object.extend(dbName);
  const newDoc = new classDb();
  newDoc.id = docId;
  newDoc.set('img1File0', theFile0);
  newDoc.set('img1File0Dim', dim0);
  newDoc.set(imgName, theFile1);
  newDoc.set(imgName + 'Dim', dim1);
  newDoc.set(thumbName, theFile2);
  newDoc.set(thumbName + 'Dim', dim2);
  const doc = await newDoc.save();
  return { doc, theFile1, theFile2, };
};

Parse.fetchDocument = async (dbName, id, selectArr = []) => {
  // const classDb = Parse.Object.extend(dbName);
  // const query = new Parse.Query(classDb);
  const query = new Parse.Query(dbName);
  // query.include('name')
  // query.select('name')
  query.equalTo('objectId', id);
  if (selectArr.length) {
    query.select(selectArr);
  }
  query.limit(1);
  const snap = await query.first();
  return (snap && snap.toJSON()) || {};
};

Parse.findDoc = async (dbName, equalToObj = {}, selectArr = []) => {
  const classDb = Parse.Object.extend(dbName);
  const query = new Parse.Query(classDb);

  // const { selectArr = [], orderBy = '' } = opt;

  if (Object.keys(equalToObj).length) {
    Object.keys(equalToObj).forEach(key => {
      query.equalTo(key, equalToObj[key]);
    });
  }
  // const total = await query.count() || 1000;

  // query.ascending('name');
  // query.ascending('order');

  // if (params.sorter) {
  //   const s = params.sorter.split('_');

  //   if (s[1] === 'descend') {
  //     query.descending(s[0]);
  //   } else {
  //     query.ascending(s[0]);
  //   }

  //   // Sorts the results in descending order by the score field
  // }
  // query.limit(pageSize);
  // query.limit(1);
  // query.skip(((current) - 1) * (pageSize));

  if (selectArr.length) {
    // console.log(selectArr)
    query.select(selectArr);
    // query.select('name');
  }

  const snap = await query.first();
  return (snap && snap.toJSON()) || {};
};

// Parse.batchSave = async (dbName, arrObj) => {
//   // const classDb = Parse.Object.extend(dbName);
//   // const query = new Parse.Query(classDb);
//   const objects = arrObj.map(i => new Parse.Object(dbName).set({ ...i }));
//   const list = await Parse.Object.saveAll(objects).catch(err => console.error(err));

//   // console.log(list);
//   // Parse.Object.saveAll(objects, {
//   //   success: function (list) {
//   //     // All the objects were saved.
//   //     console.log(list);
//   //   },
//   //   error: function (error) {
//   //     // An error occurred while saving one of the objects.
//   //     console.error(error)
//   //   },
//   // })
// }

Parse.saveall = async (dbName, equalToObj, obj) => {
  const classDb = Parse.Object.extend(dbName);
  const query = new Parse.Query(classDb);

  if (Object.keys(equalToObj).length) {
    Object.keys(equalToObj).forEach(key => {
      query.equalTo(key, equalToObj[key]);
    });
  }
  const results = await query.find();
  const saveArr = results.map(item => {
    Object.keys(obj).forEach(key => {
      const value = obj[key] || '';
      item.set(key, value);
    });
    return item;
  });

  console.log(saveArr);
  Parse.Object.saveAll(saveArr, {
    success(list) {
      console.log(list);
    },
    error(error) {
      console.error(error);
    },
  });
};

Parse.existsData = async (dbName, field = '') => {
  const query = new Parse.Query(dbName);
  query.exists(field);
  const snap = await query.find();
  const data1 = snap.map(data => data.toJSON());
  return data1;
};

Parse.queryData = async (dbName, equalToObj = {}, opt = {}, split = '_') => {
  // console.log('Parse.queryData', dbName, equalToObj, opt, split)
  if (!dbName) { console.error('Parse.queryData no dbName', dbName); return; }
  // const classDb = Parse.Object.extend(dbName);
  const query = new Parse.Query(dbName);

  const { selectArr = [], orderBy = '', limit = 10000 } = opt;
  if (selectArr.length) {
    // console.log(selectArr)
    // query.select('name');
    // query.select(...selectArr);
    // query.select('pay', 'money');
    // query.select.apply(this, selectArr)
    query.select(...selectArr)
    // query.select('objectId', 'name_cht');
  }

  if (Object.keys(equalToObj).length) {
    Object.keys(equalToObj).forEach(key => {
      query.equalTo(key, equalToObj[key]);
    });
  }
  // const total = await query.count() || 1000;

  if (!orderBy) {
    query.ascending('name');
    query.ascending('order');
  } else {
    // const s = orderBy.split('_');
    const s = orderBy.split(split);
    const p = s.pop()
    if (p === 'descend') {
      // const field = orderBy.substring(0, orderBy.indexOf('_' + p))
      const field = orderBy.substring(0, orderBy.indexOf(split + p))
      query.descending(field);
    } else if (p === 'asc') {
      // const field = orderBy.substring(0, orderBy.indexOf('_' + p))
      const field = orderBy.substring(0, orderBy.indexOf(split + p))
      query.ascending(field);
    } else {
      query.ascending(p);
    }
  }

  // query.limit(pageSize);
  // query.limit(limit || total);
  query.limit(limit);
  // query.skip(((current) - 1) * (pageSize));
  const snap = await query.find().catch(err => console.error(err));
  const data1 = snap.map(data => data.toJSON());
  // const data1 = snap.map(data => {
  //   const obj = data.toJSON();
  //   delete obj.tableData;
  //   // console.log(obj)
  //   return obj
  // });
  // console.log(data1)
  return data1;
};

Parse.getSelection = async (
  dbName,
  equalToObj = {},
  fieldObj = {},
) => {
  const { name = 'value', sort = 'order', field = 'objectId' } = fieldObj

  const arr = await Parse.queryData(dbName, equalToObj);

  const opt = [];
  arr
    .sort((a, b) => a[sort] - b[sort])
    .forEach(item =>
      opt.push({ key: item.objectId, value: item[field], text: item[name] }),
    );

  let sel = {};
  arr
    .sort((a, b) => a[sort] - b[sort])
    .forEach(item => (sel = { ...sel, [item[field]]: item[name] }));

  return { arr, opt, sel };
};

Parse.mySchema = obj => {
  console.log(obj);
  return obj;
};


Parse.countQuery = async (dbName, equalToObj = {}) => {
  const classDb = Parse.Object.extend(dbName);
  const query = new Parse.Query(classDb);

  if (Object.keys(equalToObj).length) {
    Object.keys(equalToObj).forEach(key => {
      query.equalTo(key, equalToObj[key]);
    });
  }
  const count = await query.count() || 0;
  return count
};

Parse.newData = async (dbName, obj) => {
  const newObj = { ...obj }
  const db = Parse.Object.extend(dbName);
  const newDoc = new db();
  const doc = await newDoc.save();
  newObj.objectId = doc.id;
  newObj.id = doc.id;
  newObj._id = doc.id;
  const res = await Parse.saveData(dbName, newObj);
  console.log('Parse.newData', res)
  return newObj;
}

Parse.saveData = async (dbName, obj, toastAppear) => {
  // Parse.mySchema(obj)
  // console.log('saveData', dbName, obj);

  delete obj.createdAt;
  delete obj.updatedAt;
  delete obj.ACL;
  delete obj.sessionToken;

  const classDb = Parse.Object.extend(dbName);
  const saveObj = new classDb();
  saveObj.id = obj.objectId || obj.id;
  /*
    const classSchema = new Parse.Schema(dbName);
    const schema = await classSchema.get();
    const fields = schema.fields
    Object.keys(obj).forEach(key => {
      let value = obj[key] || '';
      if (fields[key] && fields[key].type) {
        if (fields[key].type === 'String') {
          value = value + ''
        } else if (fields[key].type === 'Number') {
          value = Number(value);
        } else if (fields[key].type === 'Date') {
          // if (value && value.iso) {
          //   value = new Date(value.iso);
          // } else {
          // console.log(value)
          // value = new Date(value);
          // console.log(value)
          // }
        } else if (fields[key].type === 'Boolean') {
          value = !!value
        }
      }
      if (typeof value !== 'undefined') {
        // console.log(key, value, fields[key].type)
        saveObj.set(key, value);
      }
    });
    */
  Object.keys(obj).forEach(key => {
    let value = obj[key];
    if (key === 'order') {
      value = Number(value);
    }
    // if (value === true || value === false) {
    //   console.log(key, value)
    //   value = !!value
    // }
    saveObj.set(key, value);
  });

  await saveObj.save().catch(err => {
    // window.alert('錯誤 資料存入失敗' + err);
    console.error(err);
  });
  //沒有傳值就顯現，有傳值就不顯現
  if (toastAppear === '1') {
  } else {
    myToast({ title: '儲存成功' });
  }
};

// 刪除多個，要帶條件進來
Parse.destroyData = async (dbName, equalToObj) => {
  const query = new Parse.Query(dbName);
  if (Object.keys(equalToObj).length) {
    Object.keys(equalToObj).forEach(key => {
      query.equalTo(key, equalToObj[key]);
    });
  }
  query
    .each(data => data.destroy())
    .then(
      res => {
        console.log(res);
        // Done
      },
      error => {
        console.error(error);
        // Error
      },
    );
};

Parse.deleteData = async (dbName, obj, toastAppear) => {
  const classDb = Parse.Object.extend(dbName);
  const delObj = new classDb();
  delObj.id = obj.objectId || obj.id;
  await delObj.destroy().catch(err => console.error(err));
  if (toastAppear === '1') {
  } else {
    myToast({ title: '刪除成功' });
  }
};

Parse.signupUser = async (username, password, email = '', companyId = '', name = '', phone = '', isAdmin = false) => {
  if (!username || !password) {
    return 'error';
  }

  const user = new Parse.User();
  user.set('username', username);
  user.set('password', password);
  user.set('email', email);
  user.set('name', name);
  user.set('phone', phone);

  if (companyId) {
    user.set('authAllA', [companyId]);
    user.set('authLoginA', [companyId]);
    user.set('authUserA', [companyId]);
  }
  if (isAdmin) {
    user.set('authAdminA', [companyId]);
  }

  // other fields can be set just like with Parse.Object
  // user.set("phone", "415-392-0202");
  try {
    await user.signUp();
    myToast({ title: '註冊成功' });
    // window.alert('註冊成功');
    return '1';
    // Hooray! Let them use the app now.
  } catch (error) {
    // Show the error message somewhere and let the user try again.
    myToast({ type: 'warning', icon: 'exclamation icon', title: `Error: ${error.code} ${error.message}` });
    // window.alert(`Error: ${error.code} ${error.message}`);
  }
};

export const signinUser = async (email, password) => {
  // console.log('signinUser', email, password)
  if (!email || !password) {
    return 'error';
  }

  try {
    const user = await Parse.User.logIn(email, password);
    // console.log(user)
    // window.alert("登入成功");
    return '1';

    // Hooray! Let them use the app now.
  } catch (error) {
    // Show the error message somewhere and let the user try again.
    myToast({ type: 'warning', icon: 'exclamation icon', title: `Error: ${error.code} ${error.message}` });
    // window.alert(`Error: ${error.code} ${error.message}`);
    return '0';
  }
};

export const loginUser = async (email, password) => {
  console.log('parse client loginUser')
  return Parse.User.logIn(email, password);
};

export const getUser = () => {
  const currentUser = Parse.User.current();
  // console.log('currentUser', currentUser)
  // if (currentUser) {
  // do stuff with the user
  // } else {
  // show the signup or login page
  // window.location.href = '/auth/login';
  // }
  return currentUser;
};

Parse.getProfile = async objectId => {
  // console.log('Parse.getProfile')
  const query = new Parse.Query(Parse.User);
  query.equalTo('objectId', objectId); // find all the women
  // const women = await query.find();
  const snap = await query.find();
  return snap[0];
  // const data1 = snap.map(data => data.toJSON()) || [];
  // return data1[0]
};

Parse.logoutUser = async () => {
  await Parse.User.logOut().catch(error => console.error(error));
  // .then(() => {
  // var currentUser = Parse.User.current();  // this will now be null
  return '1';
  // });
};

Parse.upsert = async (classname = '', restrictions = {}, updates = {}) => {
  var query = new Parse.Query(classname);

  // var restrictions = JSON.parse(request.params.restrictions);
  for (var key in restrictions) {
    query.equalTo(key, restrictions[key]);
  }

  const results = await query.find().catch((error) => {
    // response.error(error);
    return { error }
  });

  // var updates = JSON.parse(request.params.updates);
  if (results.length > 0) {
    for (var i = 0; i < results.length; i++) {
      for (var key in updates) {
        results[i].set(key, updates[key]);
      }
    }

    Parse.Object.saveAll(results).then(function (results) {
      // response.success(results.length + " object(s) were updated!");
      return results.length + " object(s) were updated!";
    }, function (error) {
      return { error }
      // response.error(error);
    });
  } else {
    var objectClass = Parse.Object.extend(classname);
    var object = new objectClass();
    for (var key in updates) {
      object.set(key, updates[key]);
    }

    object.save(null, {
      success: function (obj) {
        response.success("1 new object was inserted!");
        return "1 new object was inserted!"
      },
      error: function (error) {
        // response.error(error);
        return { error }
      }
    });
  }
}

Parse.getMgyear = async (companyId) => {
  const arr = await Parse.queryData('mgyear', { companyId }, {}); // .sort((a, b) => b.value - a.value)  orderBy: 'value_descend'
  const opt = arr.map(({ value, objectId }, i) => ({ key: objectId, text: value, value }));

  let sel = {};
  arr.forEach(({ value }) => (sel = { ...sel, [value]: value }));

  const mgyearId = arr && arr.find(item => item.now_use === '-1') && arr.find(item => item.now_use === '-1').value;
  return { mgyearId, arr, sel, opt }
}

Parse.getSelectData = async (companyId, useTempleConf = {}) => {
  const { usePray1, usePray2, useTempleClass, useTempleLevel, usePayee, useAdvise } = useTempleConf
  const mgyear = await Parse.getMgyear(companyId);
  const praytype = await Parse.getSelection('praytype', { companyId }, { name: 'value', sort: 'order' });
  let templeclass = {};
  if (useTempleClass) {
    templeclass = await Parse.getSelection('templeclass', { companyId }, { name: 'value', sort: 'order' })
    templeclass.sel = { '-1': '請選擇', ...templeclass.sel }
  }
  let templelevel = {};
  if (useTempleLevel) {
    templelevel = await Parse.getSelection('templelevel', { companyId }, { name: 'value', sort: 'order' })
    templelevel.sel = { '-1': '請選擇', ...templelevel.sel }
    templelevel.opt = [{ key: 'all', value: 'all', text: '全部' }, ...templelevel.opt]
  }
  let pray1 = {};
  let pray2 = {};
  if (usePray1) {
    pray1 = await Parse.getSelection('pray1', { companyId }, { name: 'value', sort: 'order' });
    if (usePray2) {
      pray2 = await Parse.getSelection('pray2', { companyId }, { name: 'value', sort: 'order' })
    }
  }
  let templePayee = {};
  if (usePayee) {
    templePayee = await Parse.getSelection('templePayee', { companyId }, { name: 'payee', sort: 'order' })
  }
  let templeAdviser = {};
  if (useAdvise) {
    templeAdviser = await Parse.getSelection('templeAdviser', { companyId }, { name: 'advisePeople', sort: 'order' })
  }
  return { templeclass, templelevel, praytype, pray1, pray2, mgyear, templePayee, templeAdviser }
}

export default Parse;
