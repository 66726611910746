import {
  SET_AUTH_START,
  SET_AUTH_SUCCESS,
  SET_AUTH_ERROR,
  SET_CURRENT_USER,
  LOGOUT,
  // REGISTER_SUCCESS,
  GET_ERRORS,
} from '../constants';

// action 就是 dispatch傳進來的東西
const auth = (state = {}, action) => {
  if (state === null) {
    return {
      initialNow: Date.now(),
    };
  }

  switch (action.type) {
    case SET_AUTH_START: {
      // setAuth
      return {
        ...state,
        isAuthenticated: false,
        token: action.payload.token || '',
        username: '',
        userObj: {},
      };
    }
    case SET_AUTH_SUCCESS: {
      // setAuth 重新整理
      return {
        ...state,
        initialNow: Date.now(),
        isAuthenticated: true,
        token: action.payload.token || '',
        userObj: action.payload.userObj,
        username: action.payload.username,
      };
    }
    case SET_AUTH_ERROR: {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    case SET_CURRENT_USER: // 登入、註冊
      return {
        isAuthenticated: true,
        token: action.payload.token || '',
        userObj: action.payload.userObj,
        username: action.payload.username,
      };
    case LOGOUT:
      return {
        initialNow: '',
        isAuthenticated: false,
        token: '',
        username: '',
        userObj: {},
      };
    case GET_ERRORS:
      return {
        isAuthenticated: false,
        token: '',
        username: '',
        userObj: {},
        errors: action.payload.errors,
        errorCode: action.payload.errorCode,
      };
    default:
      return state;
  }
};

export default auth;
