import {
  SET_COMPANY_START,
  SET_COMPANY_SUCCESS,
  SET_COMPANY_ERROR,
} from '../constants';

export default function comp(state = null, action) {
  // 這個 state 是全域的

  if (state === null) {
    return {
      initialNow: Date.now(),
    };
  }

  switch (action.type) {
    case SET_COMPANY_START: {
      // const company = state[action.payload.company]
      //   ? action.payload.company
      //   : state.company;
      const companyId = action.payload.companyId || '';
      const companyObj = action.payload.companyObj || {};
      return {
        ...state,
        companyId,
        companyObj,
        // newCompany: action.payload.company,
      };
    }

    case SET_COMPANY_SUCCESS: {
      const companyId = action.payload.companyId || '';
      const companyObj = action.payload.companyObj || {};
      return {
        ...state,
        companyId,
        companyObj,
        // newCompany: null,
        // messages: {
        //   ...state.messages,
        //   [action.payload.company]: action.payload.messages,
        // },
      };
    }

    case SET_COMPANY_ERROR: {
      return {
        ...state,
        // newCompany: null,
      };
    }

    default: {
      return state;
    }
  }
}
