import path from 'path';
import fs from 'fs';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const myJsPDF = jsPDF;

if (typeof global.window === 'undefined') { // server端
  // 能讀ttf 盡量讀ttf, 檔案比較小
  const fontArr = [
    { filename: 'BiauKai.ttf', name: 'BiauKai' },
    { filename: 'msjh.ttf', name: 'msjh' },
  ];

  myJsPDF.API.events.push(['addFonts', function () {
    fontArr.forEach(({ filename, name }) => {
      const resolvedPath = path.resolve(path.join('./public/fonts', filename));
      const data = fs.readFileSync(resolvedPath, { encoding: 'latin1' });
      this.addFileToVFS(filename, data.toString());
      this.addFont(filename, name, 'normal');
      // console.log(name + ' ok')
    });
  }]);
}

export default myJsPDF;
