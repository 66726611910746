import React, { Component } from 'react';
import { Button, Icon, Grid, Image, Loader } from 'semantic-ui-react';
import { Modal } from 'rsuite';
import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';

import Parse from 'widget/parse';
import PreloadFile, { previewUrl } from './PreloadFile';
import { RowImage } from './RowImage';

export default class ModalUpdateFile extends Component {
  state = {
    open: false,
    file: null,
    isSaving: false,
  };

  open = () => this.setState({ open: true });

  close = () =>
    this.setState({
      open: false,
      file: null,
      isSaving: false,
    });

  toggle = () => this.setState({ open: !this.state.open });

  handleSubmit = async (type = '1') => {
    const { rowData, fieldname, onRowDataChange, isImage, dbname } = this.props;
    const { file } = this.state;
    this.setState({ isSaving: true });
    let theFile = null;
    if (isImage === '1') {// 照片
      if (type === '2') { // 原圖
        theFile = await Parse.putFile(file, 'ori'); // 單純存檔
        onRowDataChange({ ...rowData, [fieldname]: theFile });
      } else {
        let res = {};
        if (dbname) {
          res = await Parse.putFile(file, 'aa').catch(error => {
            console.error(`${error.code} ${error.message}`);
          }); // 單純存檔
          console.log(theFile);
          onRowDataChange({ ...rowData, [fieldname]: res }); // [fieldname + '_name']: theFile._source.file.name
        } else {
          res = await Parse.saveImgThumb(file, 'webimage', rowData.objectId, 'img1File', 'img1File2',);
          const { theFile1, theFile2 } = res;
          onRowDataChange({ ...rowData, [fieldname]: theFile1, [`${fieldname}2`]: theFile2, });
        }
        // console.log(res)
      }
    } else { // 檔案
      theFile = await Parse.putFile(file, 'aa').catch(error => {
        console.error(`${error.code} ${error.message}`);
      }); // 單純存檔
      onRowDataChange({ ...rowData, [fieldname]: theFile }); // , [fieldname + '_name']: theFile._source.file.name
    }

    this.setState({ isSaving: false });
    this.close();
  };

  handleChangeFile = file => {
    console.log(file);
    this.setState({ file });
  };

  handleRemove = () => {
    const { rowData, fieldname, onRowDataChange } = this.props;
    onRowDataChange({ ...rowData, [fieldname]: null });
  };

  render() {
    const { title = '修改', rowData = {}, fieldname, isImage, isUploadOri, buttonType } = this.props;
    const { open, file, isSaving } = this.state;
    // const fileUrl = (rowData && rowData[fieldname] && rowData[fieldname]["url"]) || ''
    // const ext = 'jpg';

    return (<>
      {buttonType === '1' ? <Button icon='upload' content='選擇' positive onClick={this.toggle} /> : null}
      {!buttonType ? <IconButton onClick={this.toggle}>
        <Menu size="small" />
      </IconButton> : null}
      <Modal open={open} onClose={this.close} size="lg">
        <Modal.Header>
          <Modal.Title>
            {title}
            {isImage === '1' ? '照片' : '檔案'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ padding: '20px' }}>
            <PreloadFile isImage={isImage} handleChangeFile={this.handleChangeFile} />
            {rowData[fieldname] ? (<h5>目前{isImage === '1' ? '照片' : '檔案'}</h5>) : null}
            {isImage === '1' ? (previewUrl(rowData[fieldname], true)) :
              (<RowImage rowData={rowData} field={fieldname} />)}
            <br />
            <br />
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isImage === '1' ? (
            <Button
              disabled={isUploadOri} icon="save" hidden={!file} color="green"
              content={!isSaving ? ('上傳(保留透明背景)') : (<Loader active inverted inline size="small" />)}
              onClick={() => this.handleSubmit('2')}
            />
          ) : null}
          <Button
            icon="save" onClick={this.handleSubmit} hidden={!file} primary
            content={!isSaving ? ('上傳') : (<Loader active inverted inline size="small" />)}
          />
          <Button icon="trash" content="刪除" hidden={!rowData[fieldname]} color="red" onClick={this.handleRemove} />
          <Button icon="x" content="關閉" onClick={this.close} />
        </Modal.Footer>
      </Modal>
    </>);
  }
}
