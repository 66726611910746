

import React from 'react';
import PropTypes from 'prop-types';

import { Provider as ReduxProvider } from 'react-redux';

import { connect } from 'react-redux';
import { getProfile } from 'store/actions/auth';
import ApplicationContext from './ApplicationContext';

// import withStyles from 'isomorphic-style-loader/withStyles';
// import a from "bootstrap/dist/css/bootstrap.css";
// // import a from 'bootstrap/dist/css/bootstrap.min.css';
// // import b from "assets/scss/paper-dashboard.scss?v=1.2.0";
// import b from "../../public/css/paper-dashboard.min.css";
// import c from "../../public/css/demo-dashboard.css";
// import d from "perfect-scrollbar/css/perfect-scrollbar.css";
/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context} insertCss={() => {}}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */

function App({ context, companyObj, insertCss, getProfile, children }) {
  // NOTE: If you need to add or modify header, footer etc. of the app,
  // please do that inside the Layout component.
  // await getProfile().finally();//(() => this.setState({ isLoading: false }));

  return (
    // <StyleContext.Provider value={{ insertCss }}>
    <ApplicationContext.Provider value={{ context }}>
      {React.Children.only(children)}
    </ApplicationContext.Provider>
    // </StyleContext.Provider>
  );
}
// if (typeof document !== 'undefined') {
//   const appendCssFile = (url) => {
//     const head = document.head;
//     const link = document.createElement("link");
//     link.rel = "stylesheet";
//     link.type = "text/css";
//     // link.onload = () => { cssLoaded(id); }
//     link.href = url;
//     head.appendChild(link);
//   }
//   // appendCssFile("/css/demo-kit.css");
//   // appendCssFile("/css/paper-kit.css");
//   appendCssFile("/css/demo-dashboard.css");
//   appendCssFile("/css/paper-dashboard.min.css");
// }

App.propTypes = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  // insertCss: PropTypes.func.isRequired,
  context: PropTypes.shape({
    // Universal HTTP client
    fetch: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    query: PropTypes.object,
    // Integrate Redux
    // http://redux.js.org/docs/basics/UsageWithReact.html
    ...ReduxProvider.childContextTypes,
    // ReactIntl
    // intl: IntlProvider.childContextTypes.intl,
    locale: PropTypes.string,
    company: PropTypes.string,
  }).isRequired,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = state => ({
  // companyId: state.comp.companyId,
  // companyObj: state.comp.companyObj,
  user: state.user,
  errors: state.errors,
});

export default connect(mapStateToProps, { getProfile })(App);

// export default App
// export default withStyles(a, b, c)(App)
