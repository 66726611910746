// client.js 是用於使用者在看到 HTML 後，繼續載入的 JS 檔案 (bundle.js)，可以用於綁定事件至已經渲染的 DOM 上。
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Link } from "react-router-dom";
import deepForceUpdate from 'react-deep-force-update';
import queryString from 'query-string';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { Provider } from 'react-redux';

import { createPath } from 'history';
import { IntlProvider, addLocaleData } from 'react-intl';
// This is so bad: requiring all locale if they are not needed?
/* @intl-code-template import ${lang} from 'react-intl/locale-data/${lang}'; */
import en from 'react-intl/locale-data/en';
import cs from 'react-intl/locale-data/cs';
import ja from 'react-intl/locale-data/ja';
import zh from 'react-intl/locale-data/zh';
/* @intl-code-template-end */

import App from './components/App';
import { CookiesProvider } from 'react-cookie';
// import Cookies from 'js-cookie';

import createFetch from './createFetch';
import configureStore from './store/configureStore';
import history from './history';
import { updateMeta } from './DOMUtils';
import router from './router';
// import Parse from 'widget/parse'

import { getIntl } from './store/actions/intl';
import { getComp } from './store/actions/comp';
import { getAuth } from './store/actions/auth';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://c1c9c4dba75049f1abf2a55831f3c5cd@o1201457.ingest.sentry.io/6326234",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

/* @intl-code-template addLocaleData(${lang}); */
addLocaleData(en);
addLocaleData(cs);
addLocaleData(ja);
addLocaleData(zh);
// addLocaleData([...en, ...cs, ...zh, ...ja]);

/* @intl-code-template-end */
// Universal HTTP client
const fetch = createFetch(window.fetch, {
  baseUrl: window.App.apiUrl,
});

// const apolloClient = createApolloClient();

// Initialize a new Redux store
// http://redux.js.org/docs/basics/UsageWithReact.html
const store = configureStore(window.App.state, {
  //  apolloClient,
  fetch,
  history,
});
// console.log('window.App.state', window.App.state)

// Enables critical path CSS rendering
// https://github.com/kriasoft/isomorphic-style-loader
const insertCss = (...styles) => {
  // eslint-disable-next-line no-underscore-dangle
  const removeCss = styles.map(x => x._insertCss());
  return () => {
    removeCss.forEach(f => f());
  };
};

// 設定redux的值
// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  // Universal HTTP client
  fetch: createFetch(fetch, {
    baseUrl: window.App.apiUrl,
  }),
  // Initialize a new Redux store
  // http://redux.js.org/docs/basics/UsageWithReact.html
  store,
  storeSubscription: null,
  // intl instance as it can be get with injectIntl
  intl: store.dispatch(getIntl()),
  comp: store.dispatch(getComp()),
  auth: store.dispatch(getAuth()),
};
// console.log(context)

const container = document.getElementById('app');
let currentLocation = history.location;
let appInstance;

const scrollPositionsHistory = {};
// Re-render the app when window.location changes
// 有換位置的時候
async function onLocationChange(location, action) {
  // companies
  // const companies = await Parse.queryData('company', {})
  // companyObj
  // const companyId = Cookies.get('companyId')
  // const companyObj = await Parse.findDoc('company', { objectId: companyId })
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  const isInitialRender = !action;
  try {
    context.pathname = location.pathname;
    context.query = queryString.parse(location.search);
    context.history = history;
    context.hash = location.hash;
    // context.companies = companies
    // context.changeCompany = changeCompany
    context.intl.locale = store.getState().intl && store.getState().intl.locale;
    context.companyId =
      store.getState().comp && store.getState().comp.companyId;
    context.companyObj =
      store.getState().comp && store.getState().comp.companyObj;
    // context.userObj = store.getState().auth && store.getState().auth.userObj;
    context.auth = store.getState().auth; // ////登入後，func的loginUser會將store的auth改變，並推至目標網址所以會觸發此function，故在此重新再抓一次，更新context內的auth值 或許可以有登入行為再去抓取（？

    context.semesterYear = '2020'//////For school，(應該可放companyObj底下)
    context.semester = 'A'

    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve(context);
    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }
    if (location.pathname === '/' && !(store.getState().comp && store.getState().comp.companyObj.useOfficial)) {
      history.replace('/admin');
      window.location.reload()
      return;
    }
    // console.log(context.intl.messages)
    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }
    const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;
    appInstance = renderReactApp(
      <CookiesProvider>
        <Provider store={store}>
          <IntlProvider
            key={store.dispatch(getIntl()).locale}
            locale={store.dispatch(getIntl()).locale}
            messages={store.dispatch(getIntl()).messages}
          >
            <StyleContext.Provider value={{ insertCss }}>
              <App context={context}><BrowserRouter>{route.component}</BrowserRouter></App>
            </StyleContext.Provider>
          </IntlProvider>
        </Provider>
      </CookiesProvider>,
      container,
      () => {
        if (isInitialRender) {
          // Switch off the native scroll restoration behavior and handle it manually
          // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
          if (window.history && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
          }

          const elem = document.getElementById('css');
          if (elem) elem.parentNode.removeChild(elem);
          return;
        }

        document.title = route.title;

        updateMeta('description', route.description);
        // Update necessary tags in <head> at runtime here, ie:
        // updateMeta('keywords', route.keywords);
        // updateCustomMeta('og:url', route.canonicalUrl);
        // updateCustomMeta('og:image', route.imageUrl);
        // updateLink('canonical', route.canonicalUrl);
        // etc.

        let scrollX = 0;
        let scrollY = 0;
        const pos = scrollPositionsHistory[location.key];
        if (pos) {
          scrollX = pos.scrollX;
          scrollY = pos.scrollY;
        } else {
          const targetHash = location.hash.substring(1);
          if (targetHash) {
            const target = document.getElementById(targetHash);
            if (target) {
              scrollY = window.pageYOffset + target.getBoundingClientRect().top;
            }
          }
        }

        // Restore the scroll position if it was saved into the state
        // or scroll to the given #hash anchor
        // or scroll to top of the page
        window.scrollTo(scrollX, scrollY);

        // Google Analytics tracking. Don't send 'pageview' event after
        // the initial rendering, as it was already sent
        if (window.ga) {
          window.ga('send', 'pageview', createPath(location));
        }
      },
    );
  } catch (error) {
    if (__DEV__) {
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      console.error('RSK will reload your page after error');
      window.location.reload();
    }
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChange);
onLocationChange(currentLocation);

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }

    onLocationChange(currentLocation);
  });
}
