/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';

export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';

export const SET_COMPANY_START = 'SET_COMPANY_START';
export const SET_COMPANY_SUCCESS = 'SET_COMPANY_SUCCESS';
export const SET_COMPANY_ERROR = 'SET_COMPANY_ERROR';

export const SET_AUTH_START = 'SET_AUTH_START';
export const SET_AUTH_SUCCESS = 'SET_AUTH_SUCCESS';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT = 'LOGOUT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const GET_ERRORS = 'GET_ERRORS';
