import React from 'react'
import dynamic from 'next/dynamic';

const MyMaterialTable = dynamic(() => import('./index2.js'), { ssr: false });

export * from "./index2.js";

const MaterialTable = (props) => (<>
  <MyMaterialTable {...props} />
</>)

export default MaterialTable
