import { combineReducers } from 'redux';
import auth from './auth';
import runtime from './runtime';
import intl from './intl';
import comp from './comp';

export default combineReducers({
  auth,
  runtime,
  intl,
  comp,
});
