import React, { Component } from 'react';
import { Image, Icon } from 'semantic-ui-react';
import defaultImage from 'assets/img/image_placeholder.jpg';
import Carousel, { Modal, ModalGateway } from 'react-images';

export class RowImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { rowData, field, name = 'name', photos = [], noImage, style = {}, size = "mini" } = this.props;
    const { isOpen } = this.state;

    const thumb = (rowData && rowData[field]) || {};
    const data = thumb.toJSON ? thumb.toJSON() : thumb;
    const src0 = rowData && rowData[field.substr(0, field.length - 1)];
    const source = (src0 && src0.url) || defaultImage;
    const caption = (rowData && rowData[name]) || '';
    let type = 'image';
    let items = [];
    if (photos && photos.length) {
      items = photos.map(item => {
        const src = item && item[field.substr(0, field.length - 1)];
        const caption = (item && item[name]) || '';
        const source = (src && src.url) || defaultImage;
        return { source, caption };
      });
    } else if (src0 && src0.length) {
      items.push({ caption, source });
    } else {
      let url = defaultImage;
      if (typeof data._url === 'string' && data._url.length) {
        url = data._url;
      } else if (typeof data.url === 'string' && data.url.length) {
        url = data.url;
      }

      if (url) {
        const ext = url.split('.').pop() || '';
        if (ext === 'png' || ext === 'gif' || ext === 'jpg' || ext === 'jpeg' || ext === 'tiff' || ext === 'ico' || ext === 'icon' || ext === 'jpg?61b9ec86') {
          type = 'image';
          items.push({ caption, source: url });
        } else {
          type = 'file';
        }
      }
    }
    // items = photos && photos.length > 0 ? photos.map(item => {
    //   const src = (item && item[field.substr(0, field.length - 1)]);
    //   const caption = (item && item.name) || ''
    //   const source = (src && src.url) || '#';
    //   return { source, caption };
    // }) : [{ source, caption }]

    const currentIndex =
      photos.findIndex(item => item.objectId === rowData.objectId) !== -1
        ? photos.findIndex(item => item.objectId === rowData.objectId)
        : 0;

    const options = { currentIndex };

    // if (!photos.length) {
    //   let url = null;
    //   if (typeof data._url === 'string' && data._url.length) {
    //     url = data._url
    //   } else if (typeof data.url === 'string' && data.url.length) {
    //     url = data.url
    //   }

    //   if (url) {
    //     const ext = url.split('.').pop() || '';
    //     if (ext === 'png' || ext === 'gif' || ext === 'jpg' || ext === 'jpeg' || ext === 'tiff' || ext === 'ico' || ext === 'icon') {
    //       type = 'image'
    //       items.push({ caption: caption, source: url })
    //     } else {
    //       type = 'file'
    //     }
    //   }
    // }
    // console.log(items)
    return (<>
      {!noImage && type === 'image' ? (
        <Image
          onClick={e => {
            e.preventDefault();
            this.handleOpen();
          }}
          href="#"
          src={(data && (data.url || data._url)) || defaultImage}
          target="_blank"
          rel="noopener noreferrer"
          size={size}
          style={{
            maxHeight: '300px',
            maxWidth: '300px',
            margin: '4px',
            display: 'inline-table',
            ...style,
          }}
        />
      ) : (
        <a href={data.url} target="_blank" rel="noopener noreferrer">
          <Icon name="file" color="olive" size="large" />
        </a>
      )}
      <ModalGateway>
        {isOpen ? (
          <Modal onClose={this.handleClose}>
            <Carousel views={items} {...options} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>);
  }
}

export const readFileAsURL = async file => {
  return new Promise(async (resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};

export const clickPreview = (file, isOpen, handleClose) => {
  const options = 0;
  const item = [];
  item.push({
    caption: (file.name && file.name.split('_').pop()) || '預設',
    source: file.url || file,
  });
  return (<ModalGateway>
    {isOpen ? (
      <Modal onClose={handleClose}>
        <Carousel views={item} {...options} />
      </Modal>
    ) : null}
  </ModalGateway>);
};
