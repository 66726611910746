import React, { Component, useState } from 'react';
import MaterialTable from 'widget/material-table';
import { Button, Form, Checkbox, Table, } from 'semantic-ui-react';

import Parse from 'widget/parse';
import myToast from 'widget/MyToast';
import { returnValues } from './index2';

const defColumns = [{ width: 120, title: '名稱', field: 'value' }]
const dayList = ['日', '一', '二', '三', '四', '五', '六'];

const columns = {
  'IFaceGroup': [
    { title: '順序', field: 'order', editable: 'never', defaultSort: 'asc' },
    { width: 120, title: '名稱', field: 'value' },
    // {
    //   width: 120, title: '出勤日', field: 'workDays',
    //   render: rowData => {
    //     const { workDays } = rowData; // ['1','2','3'] // 0 星期天 1 星期一
    //     workDays.sort();
    //     // console.log("workDays:", workDays)
    //     // const workDays = [1, 2, 3, 4, 6];
    //     const arr = workDays.map(item => dayList[item]);
    //     return arr.join('、');
    //   }
    // },
    // { width: 120, title: '上班時間', field: 'officeHours' },
    // { width: 120, title: '下班時間', field: 'offDutyTime' },
    // { width: 120, title: '遲到', field: 'delayMin' },
    // { width: 120, title: '早退', field: 'leaveEarly' },
    // { width: 120, title: '曠工', field: 'absenteeism' },
    { width: 120, title: '備註', field: 'ps' },
  ],
  'IFaceList': [
    { title: '順序', field: 'order', editable: 'never', defaultSort: 'asc' },
    {
      width: 120, title: '在線狀況', render: rowData => {
        const { onlineSec } = rowData;
        if (onlineSec < 60) {
          return <i className="fa fa-circle text-success" />
        } else {
          return <i className="fa fa-circle text-danger" />
        }
      }
    },
    { width: 120, title: '設備編號', field: 'sn', editable: 'never' },
    { width: 120, title: '名稱', field: 'value' },
    { width: 120, title: '備註', field: 'ps' },
    // { width: 120, title: '登錄時間', field: 'declaredAt', editable: 'never' },
  ],
  'mgyear': [
    { width: 60, title: '順序', field: 'order', editable: 'never' },
    { width: 60, title: '預設', field: 'now_use', lookup: { '-1': '是', '0': '否' }, initialEditValue: '0' },
    { title: '年份', field: 'value' },
  ],
  'pray1': [
    { width: 60, title: '預設', field: 'now_use', lookup: { '-1': '是', '0': '否' }, initialEditValue: '0' },
    { width: 60, title: '順序', field: 'order', editable: 'never', type: 'numeric' },
    { title: '法會', field: 'value' },
    { title: '備註', field: 'ps' },
  ],
  'pray2': [
    { width: 60, title: '預設', field: 'now_use', lookup: { '-1': '是', '0': '否' }, initialEditValue: '0' },
    { width: 60, title: `順序`, field: 'order', editable: 'never', type: 'numeric' },
    { title: '法會項目', field: 'value' },
    { title: '目前編號', field: 'now_num' },
    { title: '價錢', field: 'price', }
  ],
  'templeclass': [
    { width: 60, title: '順序', field: 'order', editable: 'never', type: 'numeric' },
    { title: '班別', field: 'value' },
  ],
  'templelevel': [
    { width: 60, title: '順序', field: 'order', editable: 'never', type: 'numeric' },
    { width: 60, title: '班別', field: 'value' },
  ],
  'templePayee': [
    { width: 60, title: '順序', field: 'order', editable: 'never', type: 'numeric' },
    { width: 60, title: '收款人', field: 'payee' }, // 改 name
  ],
  'templeAdviser': [
    { width: 60, title: '順序', field: 'order', editable: 'never', type: 'numeric' },
    { width: 60, title: '勸募人', field: 'advisePeople' }, // 改 name
  ],
  'cycuClass': [
    { width: 60, title: '順序', field: 'order', editable: 'never', type: 'numeric' },
    { title: '學期', field: 'semester' },
    { title: '代碼', field: 'code' },
    { title: '名稱', field: 'name' },
    { title: '人數', field: 'people', type: 'numeric' },
  ],
  // '':[],
};

export default class MyEditTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArr: [],
      loading: true,
      form: {
        facilityId: '',
      },
      rowData: {},
      showDetail: false,

      workDays: [],
      officeHours: '08:30',
      offDutyTime: '17:30',
      delayMin: 5,
      leaveEarly: 5,
      absenteeism: 15,

      buttonStatus: 'true',
    };
  }

  componentDidMount() {
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    // this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, dbName, pray1Id = '' } = props;
    const companyId = companyObj.objectId;

    let dataArr = []
    if (pray1Id) {
      dataArr = await Parse.queryData(dbName, { companyId, pray1Id });
    } else if (dbName === 'IFaceList') {
      dataArr = await Parse.queryData('IFaceList', { companyId });
      const arr = dataArr.map(({ sn }) => sn);

      const query = new Parse.Query('IFacePing')
      query.containedIn('sn', arr);
      const snap = await query.find().catch(err => console.log(err));
      const pingArr = snap.map(data => data.toJSON());

      dataArr.forEach((item, i) => {
        const obj = pingArr.find(item2 => item2.sn === item.sn);
        if (obj) {
          dataArr[i].pingedAt = obj.pingedAt;
          dataArr[i].onlineSec = Math.ceil((new Date() - new Date(obj.pingedAt)) / 1000);
        }
      })
    } else {
      dataArr = await Parse.queryData(dbName, { companyId });
    }
    // console.log(dataArr)
    this.setState({ dataArr, loading: false });
  };

  handleAdd = () => {
    // const { dbName } = this.props
    // const { newData } = this.state
    // Parse.saveData(dbName, newData);
    this.handleUpdate();
  };

  handleUpdate = () => {
    const { dbName } = this.props
    const { newData, dataArr } = this.state
    if (dbName === 'mgyear' && newData.now_use === "-1") {
      dataArr.forEach((item, i) => Parse.saveData('mgyear', item, "1"));
    } else {
      Parse.saveData(dbName, newData);
    }
  };

  handleDelete = () => {
    const { dbName } = this.props
    const { oldData } = this.state
    if (dbName === 'IFaceList') {
      const obj = {
        ...oldData,
        companyId: '',
        companyName: '',
        value: '',
        ps: ''
      }
      Parse.saveData(dbName, obj);
    } else {
      Parse.deleteData(dbName, oldData);
    }
  };

  handleBulkUpdate = (arr) => {
    const { dbName } = this.props
    arr.forEach(({ newData }) => {
      Parse.saveData(dbName, newData);
    });
  };

  changeFacilityId = (evt, { name, value }) => {
    this.setState({
      form: { ...this.state.form, [name]: value }
    })
  }

  submiteFacilityId = async () => {
    const { dataArr } = this.state
    const { companyObj } = this.props
    const { facilityId } = this.state.form

    let data = []
    data = await Parse.findDoc('IFaceList', { sn: facilityId });
    if (!data.objectId) {
      myToast({ type: 'warning', icon: 'exclamation icon', title: '此為無效的設備ID' });
      // window.alert('此為無效的設備ID')
      // this.setState({ dataArr: [], loading: false });
    } else if (data.companyId) {
      myToast({ title: '此設備ID已完成登錄' });
      // window.alert('此設備ID已完成登錄')
    } else {
      const arr = [...dataArr]
      const data1 = await Parse.findDoc('IFacePing', { sn: facilityId });
      const obj = {
        ...data,
        companyId: companyObj.objectId,
        companyName: companyObj.name
      }
      await Parse.saveData('IFaceList', obj)
      obj.onlineSec = Math.ceil((new Date() - new Date(data1.pingedAt)) / 1000);
      arr.push(obj)
      this.setState({ dataArr: arr }, () =>
        // window.alert('登錄成功！')
        myToast({ title: '登錄成功！' })
      )
    }
  }

  handleDetail = (rowData) => {
    this.setState({ rowData });
  }

  handleSave = async () => {
    const { officeHours, offDutyTime, delayMin, leaveEarly, absenteeism, rowData, workDays } = this.state
    const { value, objectId } = rowData //組別名稱
    // console.log(officeHours, offDutyTime, delayMin, leaveEarly, absenteeism, workDays, value, objectId)
    const obj = { objectId, value, officeHours, offDutyTime, delayMin, leaveEarly, absenteeism, workDays, }
    await Parse.saveData('IFaceGroup', obj);
  }

  handleChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    if (name === 'officeHours' || name === 'offDutyTime' || name === 'delayMin') {
      this.setState({ [name]: value, buttonStatus: false });
    }
  }

  handleChange1 = (value, name) => {
    if (name === 'delayMin' || name === 'leaveEarly' || name === 'absenteeism') {
      this.setState({ [name]: value, buttonStatus: false });
    }
  }

  handleChange2 = (e, item) => {
    const name = e.target.name
    const { workDays } = this.state;
    const parsed = parseInt(name);
    const index = workDays.indexOf(parsed)

    if (index === -1) { //沒有在該陣列中
      workDays.push(parsed)
      this.setState({ workDays, buttonStatus: false })
    } else { //有在陣列中
      workDays.splice(index, 1)
      this.setState({ workDays, buttonStatus: false })
    }
  };

  render() {
    const { companyObj, dbName, tableName } = this.props
    const { loading, dataArr = [], form } = this.state;
    const { facilityId } = form;
    return (<>
      {dbName === 'IFaceList' ? <>
        <Form size='big'>
          <Form.Group>
            <Form.Input inline label='設備ID' placeholder='請輸入設備Id' name='facilityId' value={facilityId} onChange={this.changeFacilityId} />
            <Form.Button size='large' content="提交" color="blue" onClick={this.submiteFacilityId} />
          </Form.Group>
        </Form>
      </> : null}
      <MaterialTable
        isLoading={loading}
        columns={columns[dbName] || defColumns}
        options={{
          dndTable: dbName,
          // exportButton: true,
          // exportPdf: (columns, data) => exportPdf(columns, data, (f1Obj && f1Obj.name) || ''),
          search: false,
        }}
        // actions={dbName === 'IFaceGroup' ?
        //   [
        //     {
        //       icon: 'view_headline',
        //       tooltip: '查看',
        //       onClick: (event, rowData) => this.handleData(rowData)
        //     }
        //   ] : null}
        // action={{
        //   icon: 'cancel',
        //   onClick: () => this.open(),
        // }}
        data={dataArr}
        title={tableName}
        // onRowClick={dbName === 'IFaceGroup' ? (event, rowData) => this.handleDetail(rowData) : null}
        // onRowClick={(event, rowData) => this.handleDetail(rowData)}
        // cellEditable={{
        //   cellStyle: {},
        //   onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
        //     return new Promise((resolve, reject) => {
        //       // console.log("newValue: " + newValue);
        //       // console.log(rowData, columnDef)
        //       const newData = { ...rowData, [columnDef.field]: newValue }
        //       // console.log('newData', newData)
        //       delete newData.tableData

        //       const data = [...dataArr];
        //       if (dbName === 'mgyear') {
        //         if (newData.now_use === "-1") {
        //           data.forEach((item, i) => data[i].now_use = '0');
        //         }
        //       }
        //       const index = data.findIndex(item => item.objectId === newData.objectId);
        //       if (index !== -1) {
        //         data[index] = newData
        //       }

        //       this.setState({ dataArr: data, newData, oldData: rowData }, () => {
        //         this.handleUpdate();
        //         resolve()
        //       });
        //     })
        //   },
        // }}
        editable={{
          onBulkUpdate: (changes) =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              // resolve(changes)
              const arr = returnValues(changes);
              arr.forEach(({ newData }) => {
                const index = data.findIndex(item => item.objectId === newData.objectId);
                if (index !== -1) {
                  data[index] = newData
                }
              });

              this.setState({ dataArr: data }, () => resolve(arr));
            }).then((arr) => this.handleBulkUpdate(arr)),
          onRowAdd: dbName === 'IFaceList' ? null : newData =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend(dbName);
              const newDoc = new db();
              const doc = await newDoc.save();
              newData.objectId = doc.id
              newData.id = doc.id
              newData._id = doc.id
              newData.companyId = companyObj.objectId
              newData.companyName = companyObj.name
              if (dbName === 'mgyear') {
                if (newData.now_use === "-1") {
                  data.forEach((item, i) => data[i].now_use = '0');
                }
              }
              if (dbName === 'godname' || (columns[dbName] && columns[dbName].find(item => item.field === 'order') && !newData.order)) {
                newData.order = data.length + 1
              }
              data.push(newData);
              this.setState({ dataArr: data, newData }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              if (dbName === 'mgyear') {
                if (newData.now_use === "-1") {
                  data.forEach((item, i) => data[i].now_use = '0');
                }
              }
              data[index] = newData;
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise(async (resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete())
        }}
      />
    </>);
  }
}
