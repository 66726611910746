// const SERVER_URL = 'parse2.infowin.com.tw'
// const appID = 'myappID'// 'parseServer',
// const clientKey = 'infowin'
// const masterKey = "jmu5ai7fzi0T"

const SERVER_URL = 'parse3.infowin.com.tw'
const appID = 'myappID'// 'parseServer',
const clientKey = 'infowin'
const masterKey = "zBXECwHVeFG0"

const obj = {
  appID,
  clientKey,
  masterKey,
  serverURL: 'https://' + SERVER_URL + '/parse',
  iwCloudURL: 'https://' + SERVER_URL + '/cloud',
  // iwCloudURL: 'http://localhost:4000/cloud',
  iwCloudTestURL: 'http://localhost:4000/cloud',
  liveQueryServerURL: 'wss://' + SERVER_URL + '/wss',
}

module.exports = obj;

// const serverUrl = 'https://parse3.infowin.com.tw/parse';
// const appID = 'parseServer';
// const masterKey = 'jgn370LsVsOh';

// const { serverURL } = require('./conf.js');
// console.log('serverURL', serverURL)

// Parse.serverURL = 'https://parse3.infowin.com.tw/parse';
// Parse.liveQueryServerURL = 'wss://parse3.infowin.com.tw/wss';
// Parse.initialize('parseServer', 'infowin', 'jgn370LsVsOh');
