import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react';
import Moment from 'moment';
import { createTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker, KeyboardDatePicker } from '@material-ui/pickers'; // TimePicker
import DateFnsUtils from '@date-io/date-fns';
import tw from 'date-fns/locale/zh-TW';

import MaterialTable from '../material-table-lib';
import jsPDF from 'widget/pdf/JspdfMyFonts';
import { fetchGz2Txt } from 'widget/http2';
import Parse from 'widget/parse';
import myToast from 'widget/MyToast';
import { RowImage } from './RowImage';
import ModalUpdateFile from './ModalUpdateFile';
import localization from './localization.js'
import { propTypes } from 'widget/material-table-lib/prop-types';
import ModalMultiAdd from './ModalMultiAdd';

export * from "../material-table-lib/components";

const theme = createTheme({
  palette: {
    primary: {
      main: '#007dea',
    },
    secondary: {
      // main: '#ff9100',
      main: '#3a76ff',
    },
  },

});

export const returnValues = (obj) => {
  return Object.keys(obj).map((key) => {
    return obj[key];
  });
}

export const codeOpt = { A: 'A', B: 'B', C: 'C', D: 'D', E: 'E', F: 'F', G: 'G', H: 'H', I: 'I', J: 'J', K: 'K', L: 'L', M: 'M', N: 'N', O: 'O', P: 'P', Q: 'Q', R: 'R', S: 'S', T: 'T', U: 'U', V: 'V', W: 'W', X: 'X', Y: 'Y', Z: 'Z' };

export const mgyearADOpt = [{ key: '2023', value: '2023', text: '2023' }, { key: '2022', value: '2022', text: '2022' }, { key: '7', value: '2021', text: '2021' }, { key: '6', value: '2020', text: '2020' }, { key: '5', value: '2019', text: '2019' }, { key: '4', value: '2018', text: '2018' }, { key: '3', value: '2017', text: '2017' }, { key: '2', value: '2016', text: '2016' }, { key: '1', value: '2015', text: '2015' }];

export const mgyearOpt = [{ key: '10', value: '112', text: '112' }, { key: '9', value: '111', text: '111' }, { key: '8', value: '110', text: '110' }, { key: '7', value: '109', text: '109' }, { key: '6', value: '108', text: '108' }, { key: '5', value: '107', text: '107' }, { key: '4', value: '106', text: '106' }, { key: '3', value: '105', text: '105' }, { key: '2', value: '104', text: '104' }, { key: '1', value: '103', text: '103' }];

export const monthOpt = [{ key: '1', value: '1', text: '一月' }, { key: '2', value: '2', text: '二月' }, { key: '3', value: '3', text: '三月' }, { key: '4', value: '4', text: '四月' }, { key: '5', value: '5', text: '五月' }, { key: '6', value: '6', text: '六月' }, { key: '7', value: '7', text: '七月' }, { key: '8', value: '8', text: '八月' }, { key: '9', value: '9', text: '九月' }, { key: '10', value: '10', text: '十月' }, { key: '11', value: '11', text: '十一月' }, { key: '12', value: '12', text: '十二月' }];

export const monthSel = { '1': '一月', '2': '二月', '3': '三月', '4': '四月', '5': '五月', '6': '六月', '7': '七月', '8': '八月', '9': '九月', '10': '十月', '11': '十一月', '12': '十二月' };

export const mgyearSel = { '112': '112', '111': '111', '110': '110', '109': '109', '2': '108', '3': '107', '4': '106', '5': '105', '6': '104', '7': '103' };

export const mgyearADSel = { '2023': '2023', '2022': '2022', '2021': '2021', '2': '2020', '3': '2019', '4': '2018', '5': '2017', '6': '2016', '7': '2015' };

export const typeCurrency = { type: 'currency', currencySetting: { currencyCode: 'TWD', minimumFractionDigits: 0 } };

const rowFile = (rowData, field) => {
  // console.log(rowData, field)
  return rowData && rowData[field] && (rowData[field]._url || rowData[field].url) ? (
    <a href={rowData[field]._url || rowData[field].url} target="_blank" rel="noopener noreferrer" >
      <Icon name="file" size="large" />
    </a>
  ) : null;
};

// 寫進去的文字欄位 可以如下：
// dateText: newData && newData.date ? Moment(newData.date).format('YYYY-MM-DD') : ''
export const dateCol = ({ field, minDate, maxDate, initialYear = '-1', ...props }) => {
  let d = new Date();
  if (initialYear !== '-1') {
    const nowYear = (new Date()).getFullYear()
    const initYear = Number(initialYear) + 1911;
    if (nowYear !== initYear) {
      d = new Date(Moment(initYear + '-01-01').format('YYYY-MM-DD'))
    }
  }
  const initValue = Moment(d).format('YYYY-MM-DD')
  return {
    ...props,
    field,
    initialEditValue: initValue,
    editComponent: props => {
      // console.log('props', props)
      // console.log('tw', tw)
      if (minDate && maxDate) {
        return (<MuiPickersUtilsProvider utils={DateFnsUtils} locale={tw}>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            value={props.value ? props.value.iso || props.value : null}
            onChange={e => {
              // console.log(e)
              props.onChange(e === null ? null : Moment(new Date(e)).format('YYYY-MM-DD'));
            }}
            clearable
            InputProps={{
              style: {
                fontSize: 13,
              },
            }}
            minDate={new Date(minDate)}
            maxDate={new Date(maxDate)}
          />
        </MuiPickersUtilsProvider>)
      } else {
        return (<MuiPickersUtilsProvider utils={DateFnsUtils} locale={tw}>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            value={props.value ? props.value.iso || props.value : null}
            onChange={e => {
              console.log(e);
              props.onChange(e === null ? null : Moment(new Date(e)).format('YYYY-MM-DD'));
            }}
            clearable
            InputProps={{
              style: {
                fontSize: 13,
              },
            }}
          />
        </MuiPickersUtilsProvider>)
      }
    },
    render: rowData => rowData[field] ? Moment(rowData[field].iso || rowData[field]).format('YYYY-MM-DD') : '',
  }
};

export const dateTimeCol = ({ title, field, width = 100, editable }) => ({
  title,
  field,
  width,
  editable,
  initialEditValue: new Date(),
  // editComponent: props => (
  // <MuiPickersUtilsProvider
  //   utils={DateFnsUtils}
  //   locale={tw}
  // >
  // <DateTimePicker
  //   format='yyyy-MM-dd HH:mm'
  //   value={props.value ? (props.value.iso || props.value) : null}
  //   onChange={e => {
  //     // console.log(e)
  //     props.onChange(e === null ? null : new Date(e))
  //   }}
  //   clearable
  //   InputProps={{
  //     style: {
  //       fontSize: 13
  //     }
  //   }}
  // />
  // </MuiPickersUtilsProvider>
  // ),
  render: rowData => {
    return rowData[field]
      ? Moment(rowData[field].iso || rowData[field]).format('YYYY-MM-DD HH:mm')
      : '';
  },
});

export const timeCol = ({ title, field, width = 100, editable }) => ({
  title,
  field,
  width,
  editable,
  initialEditValue: new Date(),
  editComponent: props => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tw}>
      <DateTimePicker
        format="HH:mm"
        value={props.value ? props.value.iso || props.value : null}
        onChange={e => {
          // console.log(e)
          props.onChange(e === null ? null : new Date(e));
        }}
        clearable
        InputProps={{
          style: {
            fontSize: 13,
          },
        }}
      />
    </MuiPickersUtilsProvider>
  ),
  render: rowData => {
    return rowData[field]
      ? Moment(rowData[field].iso || rowData[field]).format('HH:mm')
      : '';
  },
});

export const imgBtnCol = ({ title = '', field, width = 50, hidden = false, }) => ({
  width, title, field, hidden, filtering: false, headerStyle: { textAlign: 'center' },
  render: rowData => <></>,
  editComponent: props => (<ModalUpdateFile isImage="1" fieldname={field} rowData={props.rowData} onRowDataChange={props.onRowDataChange} />),
});

export const imgPreviewCol = ({ title, field, width = 120, hidden = false, photos, }) => ({
  width, title, field, hidden, filtering: false,
  // editable: 'never',
  render: rowData => (<RowImage rowData={rowData} field={`${field}2`} photos={photos} style={{ width }} />),
  editComponent: props => (<RowImage rowData={props.rowData} field={`${field}2`} photos={photos} style={{ width }} />),
});

export const fileBtnCol = ({ title = '', field, width = 50, hidden = false, }) => ({
  width, title, field, hidden, filtering: false,
  headerStyle: { textAlign: 'center' },
  render: rowData => <></>,
  editComponent: props => (<ModalUpdateFile isImage="0" fieldname={field} rowData={props.rowData} onRowDataChange={props.onRowDataChange} />),
});

export const filePreviewCol = ({ title, field, width = 80, hidden = false, }) => ({
  width,
  title,
  field,
  hidden,
  filtering: false,
  // editable: 'never',
  render: rowData => rowFile(rowData, field),
  editComponent: props => rowFile(props.rowData, field),
});

// checkFontLoaded = () => {
//   if (window.jsPDF.loaded && window.jsPDF.loaded.msjh && window.jsPDF.loaded.BiauKai) {
//     // console.log('got fonts')
//     this.setState({ fontLoaded: true });
//   } else {
//     // console.log('waiting to get fonts')
//     setTimeout(
//       () => {
//         this.checkFontLoaded()
//       },
//       1000
//     );
//   }
// }

export const exportPdf = async (columns = [], data = [], title = '', timeType,) => {
  // const hostname = window.location.hostname;
  const path1 = window.location.pathname.split('/')[1];
  // console.log('path1', path1)
  // console.log('exportPdf', columns, data, title)
  if (path1 === 'admin') {
    // fetchGz2Txt('https://hanbury.s3-ap-northeast-1.amazonaws.com/mai8/biaukai.txt.gz').then(res => {
    //   jsPDF.API.events.push(['addFonts', function () {
    //     this.addFileToVFS('BiauKai-normal.ttf', res);
    //     this.addFont('BiauKai-normal.ttf', 'BiauKai', 'normal');
    //     // console.log('BiauKai-normal ok')
    //   }])
    //   // console.log('BiauKai-normal pushed')
    //   window.jsPDF.loaded.BiauKai = 1;
    // })
    fetchGz2Txt('https://hanbury.s3-ap-northeast-1.amazonaws.com/mai8/msjh.txt')
      .then(res => {
        jsPDF.API.events.push(['addFonts', function () {
          this.addFileToVFS('msjh-normal.ttf', res);
          this.addFont('msjh-normal.ttf', 'msjh', 'normal');
          // console.log('msjh-normal ok')
        }])
        if (window && window.jsPDF) {
          window.jsPDF.loaded.msjh = 1;
        }

        const content = {
          startY: 35,
          margin: 30,
          head: [columns.map((columnDef) => columnDef.title),],
          body: data.map(item =>
            columns.map((columnDef) => {
              const fieldData = item[columnDef.field];
              if (columnDef.lookup) {
                return columnDef.lookup[item[columnDef.field]] || ''
              } else if (columnDef.type === 'datetime') {
                return (fieldData && (fieldData.iso || fieldData) && Moment(fieldData.iso || fieldData).format('YYYY-MM-DD hh:mm:ss',));
              } else {
                return fieldData;
              }
            }),
          ),
          styles: { font: 'msjh', fontStyle: 'normal', cellWidth: 46, overflow: 'linebreak', },//overflow: 'ellipsize',cellWidth: 'wrap',
          headStyles: { font: 'msjh', fontStyle: 'normal' },
          columnStyles: { country: { cellWidth: 'wrap' } },
        };
        const unit = 'pt';
        const size = 'A4';
        const orientation = 'landscape';
        const doc = new jsPDF(orientation, unit, size);
        doc.setFont('msjh').setFontSize(13);
        doc.text(title, 40, 25);
        doc.autoTable(content);
        doc.save(`${title}.pdf`);
        // console.log('msjh pushed')
      })
      .catch(err => console.log(err, 'fail to get font'));
  }
  // this.checkFontLoaded()

  // const content = {
  //   startY: 50,
  //   head: [columns.map(function (columnDef) {
  //     return columnDef.title;
  //   })],
  //   body: data.map(item => columns.map(function (columnDef) {
  //     return (item[columnDef.field] && item[columnDef.field].iso && Moment(item[columnDef.field].iso).format('YYYY-MM-DD')) || item[columnDef.field] || ''
  //     //若有iso輸出時間的格式，若無就直接將資料輸出
  //   })
  //   ),
  //   styles: { font: "msjh", fontStyle: "normal" },
  //   headStyles: { font: "msjh", fontStyle: "normal" },
  // };
  // const unit = "pt";
  // const size = "A4";
  // const orientation = "landscape";
  // let doc = new jsPDF(orientation, unit, size);
  // doc.setFont('msjh').setFontSize(15);
  // doc.text(title, 40, 40);
  // doc.autoTable(content);
  // doc.save(title + ".pdf");
  // openPDF(doc)
};

class MyMaterialTable extends React.Component {
  // tableRef = React.createRef();
  remoteDataTableRef = React.createRef();
  colRenderCount = 0;

  state = {
    dragOption: "cell",
    remoteDataOrder: [],
    openMultiAdd: false,
  };

  resetDataOrder = (data) => {
    this.state.remoteDataOrder = [];
    data.forEach((el) => this.state.remoteDataOrder.push(el.id));
  };

  reorderData = (data) => {
    return data.sort((a, b) => {
      const aPos = this.state.remoteDataOrder.indexOf(a.id);
      const bPos = this.state.remoteDataOrder.indexOf(b.id);

      return aPos === -1 ? 1 : bPos === -1 ? 1 : aPos - bPos;
    });
  };

  reorderSave = (arr) => {
    const { options = {} } = this.props
    if (!options.dndTable) {
      myToast({ type: 'warning', icon: 'exclamation icon', title: '無對應儲存資料庫, 請洽系統管理員' });
    }
    arr.forEach((item, i) => Parse.saveData(options.dndTable, item, "1"));
    myToast({ title: '儲存成功' });
  }

  openMultiAdd = (openMultiAdd) => {
    this.setState({ openMultiAdd });
  }

  render() {
    const { options = false, tableRef = null, ...props } = this.props
    const { openMultiAdd } = this.state

    const actions = props.actions || [];
    if (options && options.selectionDelFunc) {
      actions.push({
        tooltip: '多筆刪除',
        icon: 'delete',
        onClick: (e, arr) => {
          if (!window.confirm(`確認要刪除 ${arr.length} 筆嗎？`)) {
            return;
          }
          options.selectionDelFunc(arr)
        }
      })
      options.selection = true;
    }

    return (<>
      {options.multiAdd && options.multiAdd.dbName ? <ModalMultiAdd
        show={openMultiAdd}
        columns={props.columns || []}
        openMultiAdd={this.openMultiAdd}
        {...options.multiAdd}
      /> : null}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          localization={localization()}
          tableRef={tableRef || this.remoteDataTableRef}
          options={
            {
              // MIC: 以下是我們自己編的
              // dndTable: 'dbname',
              // multiAdd: {
              //   dbName: 'dbname',
              //   condition: { companyId: companyObj.objectId, companyName: companyObj.name },
              //   handleData: this.handleFetch,
              // },
              // selectionDelFunc: this.handleMultiDel1

              pageSizeOptions: [5, 10, 20, 50, 70, 100],
              padding: 'dense',
              tableLayout: 'fixed',
              columnResizable: true,
              paginationPosition: "bottom",
              addRowPosition: "first",
              ...options,
              // ...options.dndTable ? {
              // draggable: this.state.dragOption === "columns",
              // draggableRows:
              // this.state.dragOption === "cell" ||
              // this.state.dragOption === "row",
              // draggableRowsOptions: {
              //   dragHeaderContent: '排序',
              //   draggableCell: this.state.dragOption === "cell",
              //   // dragCellContent: "=",
              //   dragCellContent: <span style={{ padding: '10px' }}><i className="fas fa-arrows-alt-v"></i></span>,
              //   dragCellWidth: "20px",
              //   // dragCellWidth: "15px",
              // },
              // } : {}
              headerStyle: {
                padding: '0px 0px 0px 10px',
                lineHeight: '1.4em'
              },
              cellStyle: {
                padding: '4px 10px 4px 10px',
                overflowWrap: 'break-word'
              },
              headerSelectionProps: {
                size: 'small'
              }
            }
          }
          onRowDrop={options.dndTable ? (result) => {
            const current = this.remoteDataTableRef.current || tableRef.current
            const dataManager = current.dataManager;
            // const dataManager = this.remoteDataTableRef.current.dataManager;
            const [...arr] = dataManager.pagedData
            const startOrder = (dataManager.pagedData.length && dataManager.pagedData[0].order) || 1;

            arr.splice(result.destination.index, 0, arr.splice(result.source.index, 1)[0]);
            const newArr = arr.map((item, i) => ({ ...item, order: startOrder + i }))

            dataManager.setData(newArr);
            this.reorderSave(newArr)
          } : null}
          openMultiAdd={options.multiAdd && options.multiAdd.dbName ? this.openMultiAdd : null}
          {...props}
          actions={actions}
        />
      </MuiThemeProvider>
    </>);
  }
}
MyMaterialTable.propTypes = propTypes;
export default MyMaterialTable
