import Parse from 'widget/parse';
import AuthService from '../../services/AuthService';

import { SET_AUTH_START, SET_AUTH_SUCCESS, SET_AUTH_ERROR, SET_CURRENT_USER, LOGOUT, REGISTER_SUCCESS, GET_ERRORS, } from '../constants';

/**
 * Login user action
 */
export const loginUser = (state, history, companyObj) => dispatch => {
  // const { username, password } = state
  const { objectId: companyId } = companyObj
  let loginUsername = state.username
  let loginPassword = state.password

  const loginRecord = {
    ...state,
    companyId: companyId,
    companyName: companyObj.name,
  }
  delete loginRecord.password
  if (companyId === 'QE5QVCTAcK' || companyId === 'EFWQgEVS5j') {
    loginUsername === 'test@tw'
    loginPassword = 'test1234'
  }
  AuthService.login(loginUsername, loginPassword)
    .then(resp => {
      console.log(companyObj, resp.toJSON())
      const userObj = resp.toJSON();
      const token = userObj.objectId;
      const username = userObj.name || userObj.username || userObj.email || '';

      if (
        !userObj.authLoginA ||
        !userObj.authLoginA.length ||
        userObj.authLoginA.indexOf(companyId) === -1
      ) {
        const errorPayload = {
          errorCode: 'noauth',
          errors: '本系統無此帳號，請先註冊。', // error.response.data.error
        }
        Parse.saveData('loginRecord', { ...loginRecord, ...errorPayload, approved: '-1' })
        dispatch({
          type: GET_ERRORS,
          payload: errorPayload,
        });
      } else if (token) {
        Parse.saveData('loginRecord', { ...loginRecord, approved: '1' })
        if (process.env.BROWSER) { // 只有在前端
          const maxAge = 3650 * 24 * 3600; // 10 years in seconds
          document.cookie = `token=${token};path=/;max-age=${maxAge}`;
          // history.push(`?user=${userId}`);
          // history.push(`/admin`);
        }
        dispatch({
          type: SET_CURRENT_USER,
          payload: {
            token,
            userObj,
            username,
          },
        });
        // AuthService.saveToken(resp.objectId);
        // window.location.href = '/';
        if (process.env.BROWSER) { // 只有在前端
          history.push('/admin');
        }
      }
    })
    .catch(error => {
      console.log('error', error);
      // if (error.response.data) {
      if (error.code) {
        const errorPayload = {
          errorCode: String(error.code),
          errors: '您輸入的帳號或密碼錯誤。', // error.message
        }
        Parse.saveData('loginRecord', { ...loginRecord, ...errorPayload, approved: '0' })
        dispatch({
          type: GET_ERRORS,
          payload: errorPayload,
        });
      }
      // window.alert('您的帳號密碼輸入錯誤。')
    });
};

/**
 * Logout action
 */
export const logout = (history, toUrl = '/auth/login') => dispatch => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
  // if (toUrl === '/') {
  //   window.location.href = '/';
  // } else {
  if (process.env.BROWSER) {
    // 只有在前端
    const maxAge = 3650 * 24 * 3600; // 10 years in seconds
    document.cookie = `token=${''};path=/;max-age=${maxAge}`;
    history.push(toUrl);
  }
  // }
  // window.location.reload();
};

/**
 * Register user action
 */
export const registerUser = (data, history) => dispatch => {
  AuthService.register(data)
    .then(resp => {
      // console.log('registerUser', resp);
      if (resp.data.success) {
        //   const token = user.objectId;
        // const userObj = resp.toJSON();
        // const username = state.username;

        dispatch({
          type: REGISTER_SUCCESS,
        });
        if (process.env.BROWSER) {
          // 只有在前端
          history.push('/login');
        }
      }
    })
    .catch(error => {
      if (error.response.data) {
        dispatch({
          type: GET_ERRORS,
          payload: {
            errors: error.response.data.error,
          },
        });
      }
    });
};

/**
 * Get user profile based on token
 */
export function getProfile() {
  // console.log('getProfile')
  return dispatch => {
    return new Promise((resolve, reject) => {
      const token = AuthService.getToken();
      // console.log('getProfile token', token)
      if (token) {
        AuthService.getProfile().then(resp => {
          // console.log(resp)
          if (resp.objectId) {
            dispatch({
              type: SET_CURRENT_USER,
              payload: {
                token,
                userObj,
                username,
              },
            });
          }
          resolve();
        });
      } else {
        resolve();
      }
    });
  };
}

function getAuthFromState(state) {
  // console.log('getAuthFromState state', state)
  const auth = (state && state.auth) || {};
  const { initialNow, token = '', userObj = {}, username = '' } = auth;
  // const userMessages = (messages && messages[userId]) || {};
  // const provider = new AuthProvider({
  //   initialNow,
  //   userId,
  //   messages: userMessages,
  //   defaultAuth: 'en-US',
  // });
  // return provider.getChildContext().user;

  // const user = (state && state.user) || {};
  return {
    initialNow,
    token,
    userObj,
    username,
  };
}

export function getAuth() {
  // console.log('getAuth')
  return (dispatch, getState) => getAuthFromState(getState());
}

// 一開始連線時，判斷有沒有登入過
export function setAuth({ token = '' }) {
  // token 就是 userId
  return async (dispatch, getState, { client, history }) => {
    dispatch({
      type: SET_AUTH_START,
      payload: {
        token,
      },
    });

    try {
      // server: 只有在第一次call的時候 才會被執行
      // browser: 每次更改語系，都會進到這邊來執行，其他時候在cookies端改而已
      // console.log('setAuth: userId', userId)
      // const userObj = (token && await Parse.findDoc('User', { objectId: token })) || {}
      const user = await Parse.getProfile(token).catch(error =>
        console.log(error),
      );
      const userObj = user && user.toJSON();
      // console.log('setAuth', userObj);
      if (token && userObj.objectId) {
        // 去 reducer 設定目前的 redux 要放什麼資料
        dispatch({
          type: SET_AUTH_SUCCESS,
          payload: {
            token,
            userObj,
            username: userObj.name,
            // messages,
          },
        });
      }

      // return bound user instance at the end
      return getAuthFromState(getState());
    } catch (error) {
      dispatch({
        type: SET_AUTH_ERROR,
        payload: {
          token,
          error,
        },
      });
      return null;
    }
  };
}
