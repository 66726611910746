import React, { Component } from 'react';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import { Modal } from 'rsuite';
import { Loader } from 'semantic-ui-react';

import Parse from 'widget/parse';

class ModalMultiAdd extends Component {
  state = {
    loading: false,
    show: false,
    dataArr: [],
    newColumns: [],
  };

  componentWillReceiveProps = (nextProps) => {
    const { columns = [] } = nextProps
    const newColumns = columns.filter(item => item.field !== 'order' && item.field !== 'order_id' && item.field !== 'orderId')
    this.setState({ newColumns })
  }

  handleInput = e => {
    this.setState({ str: e.target.value });
  };

  handleSave = async () => {
    const { condition = {}, dbName, order = 0, orderName = 'order' } = this.props;
    const { str, newColumns } = this.state;
    const promises = [];
    const inputArr = str.split('\n');
    const noSpace = inputArr.filter(item => item !== '');
    if (!window.confirm(`確認添加 ${noSpace.length} 筆?`)) {
      return;
    }
    const inputObjArr = noSpace.map(item => {
      const objArr = item.split(',');
      let obj = {};
      let count = 0
      newColumns.forEach(item2 => {
        if (item2.editComponent === undefined && item2.lookup === undefined) {
          if (item2.type === 'numeric' || item2.type === 'currency') {
            obj = { ...obj, [item2.field]: Number(objArr[count]), };
          } else {
            obj = { ...obj, [item2.field]: objArr[count], };
          }
          count += 1
        }
      });
      return obj;
    });
    for (let i = 0; i < inputObjArr.length; i++) {
      const obj = { ...condition, ...inputObjArr[i], [orderName]: (order + i + 1) };
      promises.push(Parse.saveData(dbName, obj, "1"));
    }
    await Promise.all(promises).catch(error => console.log('error', error));
    this.setState({ loading: false, show: false, str: '' }, () => {
      this.props.handleData();
      this.handleClose();
    });
  };

  handleClose = () => {
    this.setState({ dataArr: [] }, () => this.props.openMultiAdd(false));
  };

  render() {
    const { show } = this.props;
    const { loading, str = '', newColumns } = this.state;
    return (<Modal open={show} onClose={this.handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>多筆添加</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <Label for="class">{newColumns.filter(item => item.editComponent === undefined && item.lookup === undefined).map(item => item.title || item.field).join(',')}</Label>
          <Input type="textarea" name="text" id="class" onChange={this.handleInput} placeholder='請填入CSV格式 (逗號分隔純文字檔)' />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button color="success" onClick={this.handleSave} disabled={!str.length}>
          {!loading ? <><i className="fa fa-upload" /> 儲存</> : <Loader active inverted inline size="small" />}
        </Button>{' '}
        <Button icon="x" onClick={this.handleClose}>關閉</Button>
      </Modal.Footer>
    </Modal>);
  }
}

export default ModalMultiAdd;
