/* eslint-disable import/prefer-default-export */

import { IntlProvider } from 'react-intl';

// import queryIntl from './intl.graphql';
import en from 'messages/en-US.json';
import ja from 'messages/ja-JP.json';
import zh from 'messages/zh-TW.json';
// import cz from 'messages/cs-CZ.json';
import {
  SET_LOCALE_START,
  SET_LOCALE_SUCCESS,
  SET_LOCALE_ERROR,
} from '../constants';

function getIntlFromState(state) {
  const intl = (state && state.intl) || {};
  const { initialNow, locale = 'en', messages } = intl;
  const localeMessages = (messages && messages[locale]) || {};
  const provider = new IntlProvider({
    initialNow,
    locale,
    messages: localeMessages,
    defaultLocale: 'zh-TW',
  });
  // console.log("provider.getChildContext().intl")
  // console.log(provider.getChildContext().intl)
  return provider.getChildContext().intl;
}

export function getIntl() {
  return (dispatch, getState) => getIntlFromState(getState());
}

export function setLocale({ locale }) {
  return async (dispatch, getState, { client, history }) => {
    dispatch({
      type: SET_LOCALE_START,
      payload: {
        locale,
      },
    });

    try {
      // WARNING !!
      // do not use client.networkInterface except you want skip Apollo store
      // use client.query if you want benefit from Apollo caching mechanisms
      // const { data } = await client.query({
      //   query: queryIntl,
      //   variables: { locale },
      // });
      // const messages = data.intl.reduce((msgs, msg) => {
      //   msgs[msg.id] = msg.message; // eslint-disable-line no-param-reassign
      //   return msgs;
      // }, {});

      // server: 只有在第一次call的時候 才會被執行
      // browser: 每次更改語系，都會進到這邊來執行，其他時候在cookies端改而已
      // console.log('setLocale: locale', locale)

      const messages = await new Promise(resolve => {
        if (locale === 'en-US') {
          resolve(en);
          // } else if (locale === 'zh-TW') {
        } else if (locale === 'ja-JP') {
          resolve(ja);
        } else {
          resolve(zh);
          //   resolve(cz);
        }
      });

      // console.log(messages)

      // 去 reducer 設定目前的 redux 要放什麼資料
      dispatch({
        type: SET_LOCALE_SUCCESS,
        payload: {
          locale,
          messages,
        },
      });

      // remember locale for every new request
      if (process.env.BROWSER) {
        // 只有在前端
        const maxAge = 3650 * 24 * 3600; // 10 years in seconds
        document.cookie = `lang=${locale};path=/;max-age=${maxAge}`;
        history.push(`?lang=${locale}`);
      }

      // return bound intl instance at the end
      return getIntlFromState(getState());
    } catch (error) {
      dispatch({
        type: SET_LOCALE_ERROR,
        payload: {
          locale,
          error,
        },
      });
      return null;
    }
  };
}
