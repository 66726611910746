let Parse = null;

// export const funcPad = (num, size) => {
const funcPad = (num, size) => {
  const s = `00000000${num}`;
  return s.substr(s.length - size);
};

if (typeof window === 'undefined') {
  // console.log('load parse_server')
  Parse = require('./parse_server.js');
} else {
  // console.log('load parse_client')
  Parse = require('./parse_client.js');
}
Parse.funcPad = funcPad;

module.exports = Parse;
